import { DefaultLayout } from "@/components/DefaultLayout";
import { Introduction } from "./Introduction";
import { Slide } from "./Slide";
import { useMobileTest } from "@/hooks";
import { Banner } from "@/components/Banner";
import bg_company from "@/assets/images/bg_company.png";
import { useTranslation } from "react-i18next";

export const Company = (): JSX.Element => {
  const { t } = useTranslation();

  const { isMobile } = useMobileTest();
  return (
    <DefaultLayout>
      <Banner title={t(`andi:company.intro.andy.title`)} bg={bg_company} className="lg:h-[780px]" />
      <Introduction />
      <Slide isMobile={isMobile} />
    </DefaultLayout>
  );
};
