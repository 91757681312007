import { STYLE_KITCHEN_LIST } from "@/contains";
import React from "react";
import { useTranslation } from "react-i18next";

export const StylePresentation = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex justify-center mt-5 px-5">
      <div className="w-full lg:w-[1200px] h-full flex flex-col justify-around space-y-16 mt-12 mb-52 px-2 lg:px-0">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {STYLE_KITCHEN_LIST.map((item, index) => (
            <>
              <a
                key={index}
                className="col-span-3 sm:col-span-1 col3-player-style group relative"
                href={`design_your_kitchen/${item.id}`}
              >
                <img src={item.imgSrc} />
                <div className="opacity-0 group-hover:opacity-100 duration-300 absolute inset-x-0 bottom-1/4 flex justify-center items-end text-xl text-white font-semibold">
                  {t(`inspiration:design.style${index + 1}.title`)}
                </div>
              </a>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
