import { EChildrenDescription, EDescription } from "./enums";
import { IChildrenDescription, IDescription } from "./types";

function renderText(children: IChildrenDescription[]) {
  return children
    .map((child) => {
      let text = child.text;
      if (child.bold) {
        text = `<strong>${text}</strong>`;
      }
      if (child.italic) {
        text = `<em>${text}</em>`;
      }
      if (child.underline) {
        text = `<u>${text}</u>`;
      }
      if (child.strikethrough) {
        text = `<s>${text}</s>`;
      }
      if (child.strikethrough) {
        text = `<s>${text}</s>`;
      }
      if (child.type === EChildrenDescription.LINK) {
        text = `<a style="color: rgb(73, 69, 255); cursor: pointer;" href="${child.url}">${child?.children?.[0].text}</a>`;
      }
      return text;
    })
    .join("");
}

export function renderDescription(description: IDescription[]) {
  if(!description) return "";
  let html = "";

  description.forEach((item, index) => {
    if (item.type === EDescription.HEADING) {
      html += `<h${item.level}>${renderText(item.children)}</h${item.level}>`;
    } else if (item.type === EDescription.PARAGRAPH) {
      html += `<p>${renderText(item.children)}</p>`;
    } else if (item.type === EDescription.IMAGE) {
      html += `<img src="${item?.image?.url}" alt="${item?.image?.alternativeText}" width="${item?.image?.width}" height="${item?.image?.height}" />`;
    } else if (item.type === EDescription.CODE) {
      html += `<pre style="background-color: rgb(246, 246, 249); padding: 12px 16px; vborder-radius: 4px;"><code>${renderText(item.children)}</code></pre>`;
    } else if (item.type === EDescription.QUOTE) {
      html += `<blockquote style="border-left: 4px solid rgb(220, 220, 228); padding: 8px 16px; color: rgb(102, 102, 135); font-weight: 400;">${renderText(item.children)}</blockquote>`;
    } else if (item.type === EDescription.LIST) {
      const listTag = item.format === "ordered" ? "ol" : "ul";
      html += `<${listTag}>`;
      item.children.forEach((listItem) => {
        html += `<li style="list-style: ${item.format === "ordered" ? "decimal" : "disc"}; ">${renderText(listItem.children || [])}</li>`;
      });
      html += `</${listTag}>`;
    }
  });
  return html;
}
