import React from "react";
import { DefaultLayout } from "@/components/DefaultLayout";
import { useTranslation } from "react-i18next";
import { StylePresentation } from "./StylePresentation";

export const DesignYourKitchen = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DefaultLayout>
      <div className="pt-40 text-center">
        <h2 className="text-3xl">{t(`inspiration:design.title`)}</h2>
        <p className="text-secondary">______</p>
      </div>
      <StylePresentation />
    </DefaultLayout>
  );
};
