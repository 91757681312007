import React from "react";
import bg from "@/assets/images/company_4.png";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Slide = ({ isMobile }: { isMobile: boolean }): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/information");
  };
  return (
    <div
      className={`w-full h-[${
        !isMobile ? "1000px" : "auto"
      }] flex justify-center`}
    >
      <div className="w-[1200px] h-full flex flex-col items-center gap-24">
        <div className="text-center text-3xl">
          <p>{t(`andi:company.slide.text1`)}</p>
          <div className="flex items-center gap-3">
            <button
              onClick={onClick}
              className="border-b-2 border-secondary py-2 px-2 flex gap-2 items-end hover:border-primary"
            >
              <h4 className="">{t(`andi:company.slide.text2`)}</h4>
              <HiOutlineArrowLongRight size={30} />
            </button>
          </div>
        </div>
        <div
          className={`w-full h-[${!isMobile ? "500px" : "150px"}] bg-slate-200`}
        >
          <img className="w-full h-full object-cover" src={bg} />
        </div>
      </div>
    </div>
  );
};
