import { useEffect, useState } from "react";
import { DefaultLayout } from "@/components/DefaultLayout";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { MOCK_DATA_DESIGN } from "@/contains";

export interface IData {
  id: number;
  data: IImgData[];
}

export interface IImgData {
  itemId: string;
  name: string;
  imgSrc: string;
  previewSrc: string;
}

export const DesignYourKitchenDetail = (): JSX.Element => {

  const [imgSrcList, setimgSrcList] = useState<IImgData[]>([]);
  const [currentImgSrc, setCurrentImgSrc] = useState("");
  const [data, setData] = useState<any>();

  const { id } = useParams() || "";
  const handleClickImg = (src: IImgData) => {
    setCurrentImgSrc(src.previewSrc);
  };
  useEffect(() => {
    if (id) {
      const res =
        MOCK_DATA_DESIGN.find((item) => item.id === Number(id)) ||
        ({} as IData);
      const defaultImg =
        res.data.find((item) => item.itemId === "default")?.previewSrc || "";
      setData(res);
      setimgSrcList(res?.data as IImgData[]);
      setCurrentImgSrc(defaultImg);
    }
  }, [id]);

  return (
    <DefaultLayout>
      <div className="flex flex-col items-center">
        <div className="flex gap-6 p-4 sm:px-14 flex-wrap lg:flex-nowrap flex-col md:flex-row">
          <div className="w-full sm:h-[615px] flex justify-center">
            <img
              className="h-full lg:object-cover sm:object-cover md:object-contain w-full"
              src={currentImgSrc}
            />
          </div>
          <div className="flex mt-5 flex-wrap justify-center sm:justify-start items-start mb-5 h-fit gap-5 cursor-pointer">
            {imgSrcList.map((item, index) => (
              <div
                key={index}
                className="w-[110px] h-[110px] border-[3px] hover:border-secondary border-transparent text-center"
                onClick={() => handleClickImg(item)}
              >
                <img className="h-full object-cover" src={item.imgSrc} />
                <p>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
