export const faqs_en = [
    {
        title: "Content",
        content: [
            { 
                question: "Honing, cleaning of concrete and rough finishes",
                answer: [
                    "Note that honed, concrete and rough finishes require more cleaning than our polished finishes. Since these finishes have more exposed surface area, metal markings, fingerprints and other signs of everyday life will show. Most of these marks can be removed effortlessly with the use of non-abrasive cleaning products. And use a non-abrasive cleaning product like Soft Scrub Liquid Gel."
                ]
            },
            {
                question: "Which cleaners should not be used?",
                answer: [
                   "Remove the stain with a dry rag. For liquids, food and stubborn stains during cooking, use a damp cloth to wipe the surface stains, then use soap or detergent to spread evenly, and then wipe back and forth with a rag until the stains are removed, then rinse with water and dry.",
                   "Avoid chemicals with high alkalinity or pH. Cleaners that may contain this substance include oven grill cleaners, floor cleaners, toilet cleaners, oil soaps, stain removers, upholstery cleaners, drainage products, rags and those designed for cleaning granite, marble and other natural stone agent, etc. In case of accidental contact with any potentially harmful product, rinse immediately with water to neutralize the effect."
                ]
            },
            {
                question: "Are quartz stone countertops hygienic?",
                answer: [
                    "Andy's Quartz surface is engineered to be highly non-porous, so it won't absorb water and won't grow bacteria, mold or mildew, keeping it safe and fresh for ultimate cleaning."
                ]
            },
            {
                question: "Can vinegar be used to clean surfaces?",
                answer: [
                    "If you want to use an eco-friendly cleaner on surfaces, use a 1:1 combination of vinegar and water. Rinse and dry the surface thoroughly afterwards."
                ]
            }
        ]
    },
    {
        title: "Project",
        content: [
            { 
                question: "What products should I avoid when protecting quartz?",
                answer: [
                    "● Avoid strong chemicals and solvents, strong acids or bases, oxidizing agents, or similar materials, regardless of pH.",
                    "● Avoid harsh cleaning products such as: oven/grill cleaners, dishwasher cleaners, floor paint removers, oil soaps, paint removers/paint strippers, etc. or anything that contains trichloroethane or dichloride Methane products",
                    "● Avoid oily products as they may leave residues. All products should be rinsed thoroughly",
                    "● If the surface comes into contact with any product that could damage it, rinse immediately with plenty of water.",
                    "● Check each cleaning product on an inconspicuous area first to make sure it won't damage the surface."
                ]
            },
            {
                question: "Is Quartz Stone Scratch Resistant?",
                answer: [
                    "The surface of Caesarstone is scratch resistant. However, avoid using sharp objects, such as knives or screwdrivers, directly on the surface. Always use a cutting board, especially on dark quartz, which tends to scratch more easily."
                ]
            },
            {
                question: "Avoid heavy accumulation",
                answer: [
                    "Avoid placing too much weight on your countertop. Excessive weight can also have an impact on the underlying cabinet structure."
                ]
            },
            {
                question: "Can a hot pot be placed on a quartz countertop?",
                answer: [
                    "Very brief moderate heat exposure can be tolerated, however, any stone can be damaged by sudden and rapid temperature changes, especially near edges. Therefore, the best practice to prevent discoloration or cracking is to use an inexpensive and easy-to-use tripod, especially when using cooking equipment such as electric frying pans, crock pots, or toasters. Do not place hot cookware directly on quartz countertops."
                ]
            }
        ]
    },
    {
        title: "Quartz",
        content: [
            {
                question: "How many designs can I choose from?",
                answer: [
                    "We offer a wide variety of different designs and every year we expand our portfolio with new colours that match the latest trends and styles.",
                    'We combine cutting-edge technology with exceptional quality, offering the latest designs and ensuring you find the perfect quartz stone. Our slabs measure 125.9" x 62.9" (320cm x 160cm), 118.1" x 55.1" (300 x 140cm) and are available in three thicknesses: 2cm and 3cm, with edge profiles, shapes, patterns and trims the widest range of possibilities. See technical specifications on each product page.'
                ]
            },
            {
                question: "Quartz stone finish",
                answer: [
                    "Stone veneers give surfaces the final look in terms of tactility (tactility), light reflection, and the overall fusion of colors and patterns. It depends on the grounding depth experienced by the slab in its final stage. Honing is a more matte gloss with a flat, smoother texture, while polishing gives a highly reflective, luminous sheen."
                ]
            },
            {
                question: "Where can quartz stone be used?",
                answer: [
                    "Since our surfaces are so hygienic and durable, they are ideal for kitchen and bathroom countertops. They are non-porous and safe, making them ideal for areas where food is prepared and served. They can be used anywhere you can imagine - from siding to stairs, from custom furniture to floors. Our products are here for you to play with, adaptable and can be used anywhere you dare."
                ]
            },
        ]
    }
]
export const faqs_vi = [
    {
        title: "Nội dung",
        content: [
            { 
                question: "Cách làm sạch, vệ sinh bề mặt bê tông và hoàn thiện gồ ghề",
                answer: [
                    "Lưu ý rằng việc làm sạch bề mặt bê tông và hoàn thiện gồ ghề yêu cầu nhiều hơn so với các hoàn thiện bóng. Do các hoàn thiện này có diện tích bề mặt tiếp xúc nhiều hơn, các vết kim loại, dấu vân tay và các dấu hiệu khác của cuộc sống hàng ngày sẽ hiển thị. Hầu hết các vết này có thể được loại bỏ dễ dàng bằng cách sử dụng các sản phẩm làm sạch không mài mòn. Hãy sử dụng một sản phẩm làm sạch không mài mòn như Soft Scrub Liquid Gel."
                ]
            },
            {
                question: "Những chất tẩy nào không nên sử dụng?",
                answer: [
                   "Loại bỏ vết bằng một khăn khô. Đối với chất lỏng, thức ăn và các vết cứng đầu trong khi nấu ăn, hãy sử dụng một khăn ẩm để lau sạch các vết trên bề mặt, sau đó sử dụng xà phòng hoặc chất tẩy để phân bố đều, và sau đó lau qua lại với một khăn cho đến khi các vết bẩn được loại bỏ, sau đó rửa bằng nước và lau khô.",
                   "Tránh các hóa chất có độ kiềm cao hoặc độ pH cao. Các loại chất tẩy có thể chứa chất này bao gồm các chất tẩy lò nướng, chất tẩy sàn nhà, chất tẩy toilet, xà phòng dầu, chất loại bỏ vết bẩn, chất làm sạch sofa, các sản phẩm thông cống, khăn và những sản phẩm được thiết kế để làm sạch đá granite, đá marble và các loại đá tự nhiên khác, v.v. Trong trường hợp tiếp xúc vô tình với bất kỳ sản phẩm có thể gây hại nào, hãy rửa ngay lập tức bằng nước để làm trung hòa tác dụng."
                ]
            },
            {
                question: "Có phải bề mặt đá hoa cương là vệ sinh không?",
                answer: [
                    "Bề mặt đá hoa cương của Andy được thiết kế để không thấm nước và không phát triển vi khuẩn, nấm mốc hoặc nấm nhầy, giữ cho nó an toàn và sạch sẽ để vệ sinh cuối cùng."
                ]
            },
            {
                question: "Có thể sử dụng giấm để làm sạch bề mặt không?",
                answer: [
                    "Nếu bạn muốn sử dụng một chất làm sạch thân thiện với môi trường trên bề mặt, hãy sử dụng hỗn hợp giữa giấm và nước với tỉ lệ 1:1. Rửa sạch và lau khô bề mặt sau đó."
                ]
            }
        ]
    },
    {
        title: "Dự án",
        content: [
            { 
                question: "Các sản phẩm nào tôi nên tránh khi bảo vệ đá hoa cương?",
                answer: [
                    "● Tránh sử dụng hóa chất mạnh và dung môi, axit hoặc bazơ mạnh, các chất oxy hóa hoặc các vật liệu tương tự, bất kể độ pH.",
                    "● Tránh sử dụng các sản phẩm làm sạch cứng nhắc như: chất tẩy lò nướng, chất tẩy máy rửa chén, chất tẩy sơn sàn, xà phòng dầu, chất loại bỏ sơn/sơn mài, v.v. hoặc bất cứ thứ gì chứa các sản phẩm tricloerothan hoặc dicaclometan",
                    "● Tránh các sản phẩm dầu vì chúng có thể để lại cặn. Tất cả các sản phẩm đều cần được rửa sạch kỹ",
                    "● Nếu bề mặt tiếp xúc với bất kỳ sản phẩm nào có thể gây hại, hãy rửa ngay lập tức bằng nhiều nước.",
                    "● Kiểm tra từng sản phẩm làm sạch trên một khu vực không rõ trước để đảm bảo rằng nó không làm hỏng bề mặt."
                ]
            },
            {
                question: "Đá hoa cương có chống trầy xước không?",
                answer: [
                    "Bề mặt của Caesarstone có khả năng chống trầy xước. Tuy nhiên, tránh sử dụng các vật nhọn, như dao hoặc tua vít, trực tiếp lên bề mặt. Luôn sử dụng một tấm cắt, đặc biệt là trên đá hoa cương màu tối, có xu hướng bị trầy xước dễ dàng hơn."
                ]
            },
            {
                question: "Tránh tích tụ nặng",
                answer: [
                    "Hãy tránh đặt quá nhiều trọng lượng lên mặt bàn của bạn. Trọng lượng quá mức cũng có thể ảnh hưởng đến cấu trúc tủ dưới."
                ]
            },
            {
                question: "Có thể đặt chảo nóng lên mặt bàn đá hoa cương được không?",
                answer: [
                    "Sự tiếp xúc với nhiệt độ trung bình rất ngắn có thể chịu được, tuy nhiên, bất kỳ loại đá nào cũng có thể bị hỏng do thay đổi nhiệt độ đột ngột và nhanh chóng, đặc biệt là ở gần các cạnh. Do đó, thực hành tốt nhất để ngăn ngừa bạch tạng hoặc nứt vỡ là sử dụng một cái chân giá rẻ và dễ sử dụng, đặc biệt là khi sử dụng thiết bị nấu ăn như chảo điện, nồi cơm chảo, hoặc lò nướng. Đừng đặt nồi nóng trực tiếp lên mặt bàn đá hoa cương."
                ]
            }
        ]
    },
    {
        title: "Đá hoa cương",
        content: [
            {
                question: "Tôi có thể chọn từ bao nhiêu mẫu thiết kế?",
                answer: [
                    "Chúng tôi cung cấp một loạt rộng lớn các thiết kế khác nhau và mỗi năm chúng tôi mở rộng danh mục của mình với các màu sắc mới phù hợp với các xu hướng và phong cách mới nhất. Chúng tôi kết hợp công nghệ tiên tiến với chất lượng nổi bật, cung cấp các thiết kế mới nhất và đảm bảo bạn tìm thấy viên đá hoa cương hoàn hảo. Các tấm của chúng tôi có kích thước 125.9\" x 62.9\" (320cm x 160cm), 118.1\" x 55.1\" (300 x 140cm) và có sẵn trong ba độ dày: 2cm và 3cm, với các hồ sơ cạnh, hình dạng, mẫu và viền có phạm vi rộng lớn nhất của các lựa chọn. Xem thông số kỹ thuật trên mỗi trang sản phẩm."
                ]
            },
            {
                question: "Hoàn thiện bề mặt đá hoa cương",
                answer: [
                    "Veneer đá cho bề mặt cái nhìn cuối cùng về tính diễn cảm (tính cảm), phản chiếu ánh sáng và sự kết hợp tổng thể của màu sắc và mẫu. Điều này phụ thuộc vào sâu độ mà tấm đá trải qua trong giai đoạn cuối cùng của nó. Honing là một lớp sáng mờ hơn với cấu trúc nhẵn, mịn màng hơn, trong khi đánh bóng tạo ra một lớp sáng rất phản chiếu, lấp lánh."
                ]
            },
            {
                question: "Đá hoa cương có thể sử dụng ở đâu?",
                answer: [
                    "Vì bề mặt của chúng tôi rất vệ sinh và bền, chúng rất lý tưởng cho các mặt bàn và lavabo bếp. Chúng không thấm nước và an toàn, khiến chúng trở nên lý tưởng cho các khu vực nơi thực phẩm được chuẩn bị và phục vụ. Chúng có thể được sử dụng ở bất kỳ nơi nào bạn có thể tưởng tượng - từ ốp lát đến cầu thang, từ đồ nội thất tùy chỉnh đến sàn nhà. Sản phẩm của chúng tôi ở đây để bạn sáng tạo với, linh hoạt và có thể được sử dụng ở bất kỳ nơi nào bạn dám."
                ]
            },
        ]
    }
]