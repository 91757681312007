import React from "react";
import { DefaultLayout } from '@/components/DefaultLayout/DefaultLayout';
import { Maintenance } from "./Maintenance";
import { Banner } from "@/components/Banner";
import { useTranslation } from "react-i18next";

const bg = 'https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30083727.jpg';
export const BecomeADistributor = (): JSX.Element => {
    const { t } = useTranslation();
    
    return (
        <DefaultLayout>
            <Banner title={t("supportBecomeDistributor:banner")} bg={bg} />
            <Maintenance />
        </DefaultLayout>
    )
}