import React, { useState } from "react";
import { RxTriangleDown } from "react-icons/rx";
import { advantages } from "@/contains/advantages";
import { useTranslation } from "react-i18next";


export const HoverSlider = ({ isMobile }: { isMobile: boolean }): JSX.Element => {
    const { t } = useTranslation();

    const [hover, setHover] = useState({
        id: 0,
        status: true
    });
    const advantageSelected = advantages[hover.id];

    const handleHover = (index: number) => {
        setHover({
            id: index,
            status: true
        });
    };

    return (
        <div className="w-[92%] mx-[4%] flex justify-center py-10">
            <div className="w-full sm:w-[600px] md:w-[800px] lg:w-[1000px] xl:w-[1200px]">
                <div className="w-full md:h-[100px] flex gap-[2px]">
                    {advantages.map((advantage, index) => (
                        <div
                            key={index}
                            className="w-[170px] h-full relative"
                            onMouseEnter={() => handleHover(index)}
                        >
                            {hover.id === index && hover.status ? (
                                <>
                                    <div className="w-full h-full bg-primary flex justify-center items-center">
                                        {
                                            !isMobile ?
                                                <p className="text-white text-xl text-center">{t(`andi:advantages.hoverSlider.${advantage.key}.title`)}</p> :
                                                <img className="absolute top-0 left-0 right-0" src={advantage.header_image} />
                                        }
                                    </div>
                                    <div className="text-secondary absolute bottom-0 right-0 left-0 flex justify-center">
                                        <RxTriangleDown size={35} />
                                    </div>
                                    <div className="absolute top-full h-[2px] left-0 r-0 w-full bg-secondary"></div>
                                </>
                            ) : (
                                <img className="absolute top-0 left-0 right-0" src={advantage.header_image} />
                            )}
                        </div>
                    ))}
                </div>
                {advantageSelected && (
                    <div className="w-full h-[465px] bg-[#e1e1e1] mt-[30px] md:mt-[2px] flex flex-col sm:flex-row">
                        <div className="pl-12 leading-relaxed pr-28 text-wrap flex justify-center items-center sm:flex-1">
                            <p>{t(`andi:advantages.hoverSlider.${advantageSelected.key}.content`)}</p>
                        </div>
                        <div className="w-full sm:w-[600px] h-[300px] sm:h-full">
                            <img className="w-full h-full object-cover" src={advantageSelected.image} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};