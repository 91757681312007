import React from "react";
import { useTranslation } from "react-i18next";
import { PiDownloadSimpleThin } from "react-icons/pi";

interface ItemDownloadProps {
  title: string;
  onClick?: () => void;
}
export const ItemDownload = ({ title, onClick }: ItemDownloadProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="flex py-3 justify-between border-b border-gray-light items-center my-6">
      <div className="text-gray-dark text-base">
        <p>{title}</p>
      </div>
      <div className="flex items-center justify-center">
        <button
          onClick={onClick}
          className="font-medium text-base gap-1 text-white bg-secondary hover:bg-primary hover:font-bold py-1 w-[200px] flex items-center justify-center"
        >
          {t(`supportECatalog:btnDownload`)}
          <PiDownloadSimpleThin />
        </button>
      </div>
    </div>
  );
};
