import { Captcha } from "@/components/Captcha/Captcha";
import Input from "@/components/Input/Input";
import React, { ChangeEvent, useRef, useState } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IContactUsForm } from "@/modules/contactUs/type";
import { useLoading } from "@/hooks/useLoading";
import { ISendMailBody } from "@/modules/cuteToSize/type";
import { emailReceive, generateCaptchaText, initializeCaptcha } from "@/modules/shared/helper";
import { CutToSizeApi } from "@/modules/cuteToSize/api";

const schema = yup.object({
    name: yup.string().required("Required field"),
    email: yup
        .string()
        .email("Please fill in the correct email address")
        .required("Required field"),
    captcha: yup.string().required("Required field"),
    infor: yup.string().required("Required field"),
});

export const Maintenance = (): JSX.Element => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<IContactUsForm>({
        resolver: yupResolver(schema),
    });
    const { openLoading, closeLoading } = useLoading();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [captchaText, setCaptchaText] = useState<string>("");
    const onSubmit: SubmitHandler<IContactUsForm> = async (data) => {
        try {
            const { name, email, infor, captcha } = data;
            if (captcha !== captchaText) {
                setError("captcha", {
                    message: "Please enter the correct verification code",
                });
                return;
            }
            openLoading();
            const body: ISendMailBody = {
                to: emailReceive || "",
                subject: "MAINTENANCE",
                text: `Name: ${name}<br>Email: ${email}<br>Information: ${infor}`,
            };
            const res = await CutToSizeApi.sendMail(body);
            if (res) {
                const canvas = canvasRef.current;
                if (canvas) {
                    const ctx = canvas.getContext("2d");
                    initCaptcha(ctx);
                }
                reset(undefined);
            }
        } catch (error) {
            console.log(error);
        } finally {
            closeLoading();
        }
    };

    const initCaptcha = (ctx: any) => {
        const newCaptcha = generateCaptchaText();
        initializeCaptcha(ctx, newCaptcha);
        setCaptchaText(newCaptcha);
    };

    const hancleCheckCaptcha = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== captchaText) {
            setError("captcha", {
                message: "Please enter the correct verification code",
            });
            return;
        } else {
            clearErrors("captcha");
            setValue("captcha", e.target.value);
        }
    };
    return (
        <div className="w-[92%] mx-[4%] flex justify-center mb-20">
            <div className="w-full max-w-[1200px] h-full flex flex-col items-center py-12">
                <div className="text-[#555] flex gap-2 justify-center pb-4">
                    <h2 className="text-xl font-semibold">{t("supportBecomeDistributor:title")}</h2>
                </div>
                <div className="bg-secondary h-[1px] w-20"></div>
                <div className="flex flex-col md:flex-row md:gap-32 my-20">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="w-full md:w-[560px] bg-gray-light py-2 px-6 md:mb-auto mb-3"
                    >
                        <h4 className="text-lg my-4">
                            {t("supportBecomeDistributor:sendUsAMessage")}
                        </h4>
                        <div className="flex flex-col md:flex-row md:gap-3 mb-3">
                            <div className="flex-1 md:mb-auto mb-3">
                                <Input
                                    {...register("name")}
                                    error={errors.name}
                                    customclass="bg-white w-full font-base outline-none py-[6px] px-3"
                                    placeholder={t("supportBecomeDistributor:yourName")}
                                />
                            </div>
                            <div className="flex-1">
                                <Input
                                    {...register("email")}
                                    error={errors.email}
                                    customclass="bg-white w-full font-base outline-none py-[6px] px-3"
                                    placeholder={t("supportBecomeDistributor:yourEmail")}
                                />
                            </div>
                        </div>
                        <div className="w-full h-[130px] mb-7">
                            <textarea
                                {...register("infor")}
                                className={`w-full h-full bg-white outline-none py-2 px-3 ${errors.infor ? "border-red-500 border" : ""
                                    }`}
                                placeholder={t("supportBecomeDistributor:information")}
                            ></textarea>
                            {errors.infor && (
                                <p className="text-red-500 text-sm">{errors.infor.message}</p>
                            )}
                        </div>
                        <div className="flex flex-col-reverse md:flex-row md:gap-3 mb-3">
                            <div className="flex-1">
                                <Input
                                    {...register("captcha")}
                                    error={errors.captcha}
                                    onChange={(e) => hancleCheckCaptcha(e)}
                                    customclass="bg-white w-full font-base outline-none py-[6px] px-3"
                                    placeholder={t("supportBecomeDistributor:verifyCode")}
                                />
                            </div>
                            <div className="px-2 h-[48px] md:h-[36px] flex justify-center md:mb-auto mb-3">
                                <div className="h-full w-[auto]">
                                    <Captcha
                                        canvasRef={canvasRef}
                                        setCaptchaText={setCaptchaText}
                                    />
                                </div>
                                {/* <img
                  className="h-full w-[auto]"
                  src="https://www.andiquartz.com/Common/GenerateLeavewordCaptcha?timestamp=0.023990086929922994"
                /> */}
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button
                                type="submit"
                                className="bg-primary text-white py-2 px-6 font-meidum text-sm"
                            >
                                {t("supportBecomeDistributor:submit")}
                            </button>
                        </div>
                    </form>
                    <div className="flex-1 flex flex-col justify-center text-sm leading-loose">
                        <p className="mb-14">
                            <div dangerouslySetInnerHTML={{ __html: t("supportBecomeDistributor:content") }} />
                        </p>

                        <div className="flex flex-col">
                            <strong>
                                <p>{t("supportBecomeDistributor:andyContactInformation")}</p>
                            </strong>
                            <p>{t("supportBecomeDistributor:address")}</p>
                            <p><strong>{t("supportBecomeDistributor:telephone")}:</strong> 0585 272 888 - 0586 272 888</p>
                            {/* <p><strong>Fax:</strong> 0766-8226218</p> */}
                            <p><strong>{t("supportBecomeDistributor:mailbox")}:</strong> sales@andistone.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}