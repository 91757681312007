import * as navigation from './navigation.json';
import * as footer from './footer.json';
import * as homepage from './homepage.json';
import * as productDetail from './product-detail.json';
import * as productList from './product-list.json';
import * as supportECatalog from './support-ecatalog.json';
import * as supportCutToSize from './support-cutTosize.json';
import * as supportCare from './support-care.json';
import * as supportBecomeDistributor from './support-becomeDistributor.json';
import * as supportContact from './support-contact.json';
import * as supportFaq from './support-faq.json';
import * as andi from './andi.json';
import * as inspiration from './inspiration.json';

const languageSection = {
    navigation,
    footer,
    homepage,
    productDetail,
    productList,
    supportECatalog,
    supportCutToSize,
    supportCare,
    supportBecomeDistributor,
    supportContact,
    supportFaq,
    andi,
    inspiration
};

export default languageSection;