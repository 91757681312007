import { getImgUrl } from "@/modules/shared/helper";
import React from "react";
import { useTranslation } from "react-i18next";
interface Props {
    keyData: string;
    large_text: string;
    small_text: string;
    image: any;
}
export const ProductCard = ({ keyData, large_text, small_text, image }: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="w-full lg:w-[32%] h-[510px] flex flex-col">
            <div className="w-full h-[370px]">
                <img className="w-full h-full object-cover" src={getImgUrl(image.data[0].attributes)} />
            </div>
            <div className="flex-1 bg-[#eeeeee] py-2 px-3">
                <h4 className="font-bold text-lg max-h-[56px]">
                    {/* {t(`homepage:cardList.${keyData}.title`)} */}
                    {large_text}
                </h4>
                <div className="my-3 h-[2px] w-10 bg-secondary"></div>
                <p className="text-sm max-h-[40px] overflow-hidden">
                    {/* {t(`homepage:cardList.${keyData}.desc`)} */}
                    {small_text}
                </p>
            </div>
        </div>
    )
}