import React from "react";
import { ProductItem } from "./ProductItem";
import { useTranslation } from "react-i18next";
import { IProductsResponse } from "@/modules/productsPage/types";
interface ProductListProps {
    data: IProductsResponse[];
  }
export const ProductList = ({ data }: ProductListProps):JSX.Element => {

    const { t } = useTranslation();

    const filterProducts = (category: string) =>{
        return data.filter((item) => item?.category?.data?.attributes?.name === category);
    }

    return (
        <div className="w-full">
            <ProductItem title={t(`navigation:products.newSeries`)} data={filterProducts("New Series")}/>
            <ProductItem title={t(`navigation:products.naturalSeries`)} data={filterProducts("Natural Series")}/>
            <ProductItem title={t(`navigation:products.fashionSeries`)} data={filterProducts("Fashion Series")}/>
            <ProductItem title={t(`navigation:products.classicSeries`)} data={filterProducts("Classic Series")}/>
        </div>
    )
}