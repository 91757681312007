import React from "react";
import { useTranslation } from "react-i18next";

export const Comparison = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="w-[92%] mx-[4%] md:h-[900px] flex justify-center mt-20">
            <div className="w-[1200px] h-full flex flex-col items-center justify-between">
                <div className="mb-4">
                    <h4 className="text-3xl font-bold text-[#555555]">{t(`andi:advantages.comparison`)}</h4>
                    <div className="h-[1px] w-full bg-secondary mt-3"></div>
                </div>
                <div className="md:h-[812px] w-full">
                    <img className="w-full h-full object-cover" src="https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30337352.jpg" />
                </div>
            </div>
        </div>
    )
}