import axios from "@/apis/axios";
import { IResponseResult } from "../shared/types";
import { ICategoryResponse, IProductsResponseResults } from "./types";

export class ProductsApi {
  static getCategories(): Promise<IResponseResult<ICategoryResponse[]>> {
    return axios.get("categories").then((res) => res.data);
  }

  static getProducts(
    categoryId?: number
  ): Promise<IResponseResult<IProductsResponseResults[]>> {
    const qs = require("qs");
    const query = categoryId
      ? qs.stringify(
          {
            filters: {
              category: {
                id: {
                  $eq: categoryId,
                },
              },
            },
          },
          {
            encodeValuesOnly: true, // prettify URL
          }
        )
      : "";
    return axios.get(`products?populate=*&${query}`).then((res) => res.data);
  }

  static getProductDetail(
    id: string
  ): Promise<IResponseResult<IProductsResponseResults>> {
    return axios.get(`products/${id.toString()}?populate=*`).then((res) => res.data);
  }

  static getProductsByCategory(
    id: string
  ): Promise<IResponseResult<IProductsResponseResults[]>> {
    return axios.get(`products?filters[category][id][$eq]=${id}&populate=*`).then((res) => res.data);
  }
}
