import React from "react";
import { useTranslation } from "react-i18next";

export const Content = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-[450px] flex justify-center">
      <div className="w-[1200px] h-full flex flex-col items-center justify-center gap-10">
        <h3 className="font-bold text-3xl"> {t(`andi:advantages.title`)}</h3>
        <div className="text-center text-xl text-secondary">
          <p className="font-semibold">|</p>
          <p className="">◇</p>
          <p className="font-semibold">|</p>
        </div>
        <p className="text-xl text-center mx-10">
          {t(`andi:advantages.content`)}
        </p>
      </div>
    </div>
  );
};
