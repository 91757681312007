import React from "react";
import { DefaultLayout } from "@/components/DefaultLayout";
import { Content } from "./Content";
import { Banner } from "@/components/Banner";

const bg = 'https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30083089.jpg';
export const CareMaintenance = ():JSX.Element => {
    return (
        <DefaultLayout>
            <Banner title="CARE & MAINTENANCE" bg={bg} />
            <Content />
        </DefaultLayout>
    )
}