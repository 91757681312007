import { generateCaptchaText, initializeCaptcha } from "@/modules/shared/helper";
import React, { useEffect } from "react";

interface CaptchaProps {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  setCaptchaText: React.Dispatch<React.SetStateAction<string>>;
}
export const Captcha = ({
  canvasRef,
  setCaptchaText,
}: CaptchaProps): JSX.Element => {
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      initCaptcha(ctx);
    }
  }, []);


  const initCaptcha = (ctx: any) => {
    const newCaptcha = generateCaptchaText();
    initializeCaptcha(ctx, newCaptcha);
    setCaptchaText(newCaptcha);
  };

  return (
    <canvas
      className="cursor-pointer"
      onClick={() =>
        canvasRef.current && initCaptcha(canvasRef.current.getContext("2d"))
      }
      ref={canvasRef}
      width="100"
      height="70"
    ></canvas>
  );
};
