import React from "react";
import { DefaultLayout } from "@/components/DefaultLayout";
import { ProductPresentation } from "./ProductPresentation";
import { Banner } from "@/components/Banner";
import bg from '@/assets/images/product_information_banner.jpeg'
import { useTranslation } from "react-i18next";

export const AndiProductInformation = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <DefaultLayout>
            <Banner title={t(`andi:productInfor.title`)} bg={bg} />
            <ProductPresentation />
        </DefaultLayout>
    )
}