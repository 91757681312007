import { IFormatImg, IImage } from "./types";
import { EKeySizeImg } from "./enums";

export const baseURL = process.env.REACT_APP_API_URL;
export const emailReceive = process.env.REACT_APP_EMAIL_RECEIVE;
// export const baseURL = 'http://localhost:1337';

export const getImgUrl = (data: IImage) => {
  return `${baseURL}${data.url}`;
};

export const getImgUrlWithFormat = (
  data: IFormatImg,
  keySize?: EKeySizeImg
) => {
  return `${baseURL}${data[keySize || EKeySizeImg.THUMBNAIL]?.url}`;
};

export const mappingDataFromApi = (data: any) => {
  return {
    ...data,
    ...data.attributes,
  };
};

export const mappingDatasFromApi = (data: Array<any>) => {
  const result = data.map((item) => ({
    ...item,
    ...item.attributes,
  }));
  return result;
};

const generateRandomChar = (min: number, max: number) =>
  String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min));

export const generateCaptchaText = () => {
  let captcha = "";
  for (let i = 0; i < 4; i++) {
    const charType = Math.floor(Math.random() * 3);
    if (charType === 0) {
      captcha += generateRandomChar(65, 90); // A-Z
    } else if (charType === 1) {
      captcha += generateRandomChar(97, 122); // a-z
    } else {
      captcha += generateRandomChar(48, 57); // 0-9
    }
  }
  return captcha
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");
};

const drawCaptchaOnCanvas = (ctx: any, captcha: any) => {
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  const textColors = ["rgb(0,0,0)", "rgb(130,130,130)"];
  const letterSpace = 80 / captcha.length;
  for (let i = 0; i < captcha.length; i++) {
    const xInitialSpace = 25;
    ctx.font = "16px Roboto Mono";
    ctx.fillStyle = textColors[Math.floor(Math.random() * 2)];
    ctx.fillText(
      captcha[i],
      xInitialSpace + i * letterSpace,

      // Randomize Y position slightly
      Math.floor(Math.random() * 16 + 25),
      100
    );
  }
};

export const initializeCaptcha = (ctx: any, newCaptcha: string) => {
  drawCaptchaOnCanvas(ctx, newCaptcha);
};
