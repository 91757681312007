import React, { useEffect, useState } from "react";
import { DefaultLayout } from "@/components/DefaultLayout";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ProductsApi } from "@/modules/productsPage/api";
import { IProductsResponse } from "@/modules/productsPage/types";
import { getImgUrl, mappingDataFromApi } from "@/modules/shared/helper";
import { news_series } from "@/contains";
import { renderDescription } from "@/modules/productsPage/constants";
import { useLoading } from "@/hooks/useLoading";
const settings = {
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
};

export const ProductInfo = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [categoryId, setCategoryId] = useState<string>('');
  const [imgSrcList, setimgSrcList] = useState<string[]>([]);
  const [relateProductList, setRelateProductList] = useState<any[]>([]);
  const [currentImgSrc, setCurrentImgSrc] = useState(imgSrcList[0]);
  const [product, setProduct] = useState<IProductsResponse>(
    {} as IProductsResponse
  );
  const { openLoading, closeLoading } = useLoading();

  const { id } = useParams() || "";
  const handleClickImg = (src: string) => {
    setCurrentImgSrc(src);
  };
  useEffect(() => {
    if (id) {
      fetchProductsDetail();
    }
  }, [id]);

  useEffect(() => {
    if (categoryId) {
      fetchRelateProduct();
    }
  }, [categoryId]);

  const fetchRelateProduct = async () => {
    try {
      openLoading();
      const response = await ProductsApi.getProductsByCategory(categoryId);
      if (response?.data) {
        const mapData = response.data.map((e) => mappingDataFromApi(e));
        setRelateProductList(mapData);
      }
    } catch (error: any) {
      console.log("error", error);
    } finally {
      closeLoading();
    }
  };

  const fetchProductsDetail = async () => {
    try {
      openLoading();
      const response = await ProductsApi.getProductDetail(id || "");
      if (response?.data) {
        const mapData = mappingDataFromApi(response?.data);
        const product_infor_images = mapImgData(mapData);
        setProduct(mapData);
        setimgSrcList(product_infor_images);
        setCurrentImgSrc(product_infor_images[0]);
        setCategoryId(mapData?.category?.data?.id);
      }
    } catch (error: any) {
      console.log("error", error);
    } finally {
      closeLoading();
    }
  };

  const mapImgData = (data: IProductsResponse) =>
    data?.images?.data?.map((item) => {
      return getImgUrl(item.attributes);
    }) || [];

  return (
    <DefaultLayout>
      <div className="flex flex-col items-center">
        <div className="w-full flex flex-col p-2 sm:px-28">
          <div className="w-full h-[181px] sm:h-[615px] flex justify-center">
            <img
              className="w-full h-full object-contain sm:object-cover"
              src={currentImgSrc}
            />
          </div>
          <div className="flex w-full mt-5 justify-between items-center">
            <div className="text-[#99999b]">
              <SlArrowLeft size={20} />
            </div>
            <div className="flex-1 flex items-center justify-center gap-2">
              {imgSrcList.map((item, index) => (
                <div
                  key={index}
                  className="w-[83px] h-[83px] sm:w-[176px] sm:h-[111px] border-[3px] hover:border-secondary border-transparent"
                  onClick={() => handleClickImg(item)}
                >
                  <img className="w-full h-full object-cover" src={item} />
                </div>
              ))}
            </div>
            <div className="text-[#99999b]">
              <SlArrowRight size={20} />
            </div>
          </div>
          <div className="text-center font-bold text-2xl">{product.name}</div>
          <div className="w-full flex justify-center">
            <div className="flex w-[900px] text-xs text-gray-500 justify-between items-center my-20">
              <div className="flex items-center gap-2">
                <SlArrowLeft size={10} /> {t(`productDetail:prv`)}: AD8035
              </div>
              <div>
                <button className="border-gray-500 px-5 py-1 border rounded-md">
                  {t(`productDetail:content`)}
                </button>
              </div>
              <div className="flex items-center gap-2">
                {t(`productDetail:nxt`)}: AD9052
                <SlArrowRight size={10} />
              </div>
            </div>
          </div>
          <div className="my-6">
            <h4 className="font-bold text-2xl mb-8">
              {t(`productDetail:techDetail.title`)}
            </h4>
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: renderDescription(product?.description),
              }}
            ></div>
          </div>

          <div className="w-full py-28">
            <h4 className="font-bold text-xl text-center">
              {t(`productDetail:like`)}
            </h4>
            <div className="w-full flex justify-between items-center">
              <div className="text-[#99999b] z-10">
                <SlArrowLeft size={30} />
              </div>
              <div className="w-full overflow-hidden sm:overflow-unset sm:w-[1185px] my-5">
                <Slider {...settings}>
                  {relateProductList.map((slider, index) => (
                    <div
                      className="w-[210px] h-[200px] max-w-full max-h-full px-2"
                      key={index}
                      onClick={() => { navigate(`/products/${slider.id}` )}}
                    >
                      <div className="w-full h-full border hover:border-secondary">
                        <div className="h-[160px] w-full">
                          <img
                            className="w-full h-full object-cover"
                            src={getImgUrl(slider.images.data[0].attributes)}
                          />
                        </div>
                        <div className="flex items-center pl-2">{slider.name}</div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="text-[#99999b] z-10">
                <SlArrowRight size={30} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
