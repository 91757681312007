import React from "react";
import { DefaultLayout } from "@/components/DefaultLayout";
import { Content } from "./Content";
import { Banner } from "@/components/Banner";
import { useTranslation } from "react-i18next";

const bg = 'https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30084551.jpg';
export const ContactUs = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <DefaultLayout>
      <Banner title={t("supportContact:title")} bg={bg} />
      <Content />
    </DefaultLayout>
  );
};
