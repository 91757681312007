import React from "react";
import {
  dropdownInspirations,
  dropdownProducts,
  dropdownSupport,
} from "@/contains";
import { NavLink } from "react-router-dom";
import {
  FaLocationDot,
  FaPhone,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaEnvelope,
} from "react-icons/fa6";
import { FaPinterest } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const Footer = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="border-t-[6px] border-t-secondary">
      <div className="w-full flex justify-center bg-primary">
        <div className="w-[1440px] flex justify-between px-2 py-10 gap-20 flex-col lg:flex-row">
          <div className="w-[300px]">
            <h1 className="text-white font-normal text-xl mb-2">{t("footer:warmTips.title")}</h1>
            <div className="h-[2px] w-20 bg-secondary"></div>
            <div className="my-5">
              <p className="text-white">
                {t("footer:warmTips.content")}
              </p>
            </div>
          </div>
          <div className="flex gap-20 flex-col lg:flex-row">
            <div className="flex gap-20 justify-between flex-col md:flex-row">
              <div className="overflow-hidden">
                <h1 className="text-white font-normal text-xl mb-2">
                  {t("navigation:products.allSeries")}
                </h1>
                <div className="h-[2px] w-20 bg-secondary"></div>
                <ul className="pl-3 mt-5 overflow-hidden">
                  {dropdownProducts.map((nav, index) => (
                    <li key={index} className="text-white hover:font-bold mt-2">
                      <NavLink to={nav.link}>
                        {t(`navigation:${nav.main}.${nav.key}`)}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h1 className="text-white font-normal text-xl mb-2">
                  {t("navigation:inspiration.designInspiration")}
                </h1>
                <div className="h-[2px] w-20 bg-secondary"></div>
                <ul className="pl-3 mt-5">
                  {dropdownInspirations.map((nav, index) => (
                    <li key={index} className="text-white hover:font-bold mt-2">
                      <NavLink to={nav.link}>
                        {t(`navigation:${nav.main}.${nav.key}`)}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h1 className="text-white font-normal text-xl mb-2">
                  {t("navigation:support.support")}
                </h1>
                <div className="h-[2px] w-20 bg-secondary"></div>
                <ul className="pl-3 mt-5">
                  {dropdownSupport.map((nav, index) => (
                    <li key={index} className="text-white hover:font-bold mt-2">
                      <NavLink to={nav.link}>
                        {t(`navigation:${nav.main}.${nav.key}`)}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="w-[300px]">
              <h1 className="text-white font-normal text-xl mb-2">
              {t("footer:contactInformation.title")}
              </h1>
              <div className="h-[2px] w-20 bg-secondary"></div>
              <div className="my-5">
                <ul className="w-full">
                  <li className="flex justify-between gap-4 items-start">
                    <div className="w-3 h-3 text-white">
                      <FaLocationDot size={20} />
                    </div>
                    <p className="text-white px-1 break-words">
                    {t("footer:contactInformation.address")}
                    </p>
                  </li>
                  <li className="flex justify-between gap-4 items-start mt-3">
                    <div className="w-3 h-3 text-white">
                      <FaPhone size={20} />
                    </div>
                    <p className="text-white px-1 flex-1 break-words">
                      0585 272 888 - 0586 272 888
                    </p>
                  </li>
                  <li className="flex justify-between gap-4 items-start mt-3">
                    <div className="w-3 h-3 text-white">
                      <FaEnvelope size={20} />
                    </div>
                    <p className="text-white px-1 flex-1 break-words">
                      Office@andistone.com
                      <br />
                      Sales@andistone.com
                    </p>
                  </li>
                  <li className="flex gap-3 mt-5 text-white">
                    <a href="#do-nth-block">
                      <FaFacebookF size={22} />
                    </a>
                    <a href="#do-nth-block">
                      <FaTwitter size={22} />
                    </a>
                    <a href="#do-nth-block">
                      <FaPinterest size={22} />
                    </a>
                    <a href="#do-nth-block">
                      <FaInstagram size={22} />
                    </a>
                    <a href="#do-nth-block">
                      <FaLinkedin size={22} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex justify-center items-center h-[50px]">
                <p className="text-gray-500">本网站由阿里云提供云计算及安全服务  本网站支持 IPv6</p>
            </div> */}
    </div>
  );
};
