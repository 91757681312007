import React from "react";
import company1 from "@/assets/images/company_1.png"
import company2 from "@/assets/images/company_2.png"
import company3 from "@/assets/images/company_3.png"
import { useTranslation } from "react-i18next";

export const Introduction = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="w-[92%] mx-[4%] flex justify-center">
            <div className="w-full max-w-[1200px] flex gap-24 my-24 flex-col justify-evenly">
                <div className="flex flex-col md:flex-row gap-12">
                    <div className="w-full md:w-[600px]">
                        <img className="w-full h-full object-cover" src={company1} alt="Company 1"/>
                    </div>
                    <div className="flex flex-1 flex-col justify-center">
                        <div className="flex items-center gap-3 mb-7">
                            <div className="text-gray-700 border-b-2 border-secondary py-2 px-2 flex gap-2 items-center">
                                <h4 className="text-lg">{t(`andi:company.intro.andy.title`)}</h4>
                            </div>
                        </div>
                        <div className="">
                            <p className="leading-loose font-normal text-black text-sm">
                                {t(`andi:company.intro.andy.content`)}                            
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row-reverse gap-12">
                    <div className="w-full md:w-[600px]">
                        <img className="w-full h-full object-cover" src={company2} alt="Company 2" />
                    </div>
                    <div className="flex flex-1 flex-col justify-center">
                        <div className="flex items-center gap-3 mb-7">
                            <div className="text-gray-700 border-b-2 border-secondary py-2 px-2 flex gap-2 items-center">
                                <h4 className="text-lg">{t(`andi:company.intro.quality.title`)}</h4>
                            </div>
                        </div>
                        <div className="">
                            <p className="leading-loose font-normal text-black text-sm">
                            {t(`andi:company.intro.quality.content`)}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-12">
                    <div className="w-full md:w-[600px]">
                        <img className="w-full h-full object-cover" src={company3} alt="Company 3" />
                    </div>
                    <div className="flex flex-1 flex-col justify-center">
                        <div className="flex items-center gap-3 mb-7">
                            <div className="text-gray-700 border-b-2 border-secondary py-2 px-2 flex gap-2 items-center">
                                <h4 className="text-lg">{t(`andi:company.intro.why.title`)}</h4>
                            </div>
                        </div>
                        <div className="">
                            <p className="leading-loose font-normal text-black text-sm">
                            {t(`andi:company.intro.why.content`)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}