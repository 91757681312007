import { IProductsResponse } from "@/modules/productsPage/types";
import { getImgUrl } from "@/modules/shared/helper";
import { useNavigate } from "react-router-dom";


interface Props {
  title: string;
  data: IProductsResponse[];
}
export const ProductItem = ({ title, data }: Props): JSX.Element => {
    const navigate = useNavigate();
    const handleClick = (id: number) => {
        navigate(`${id}`)
    }
  return (
    <div className="flex justify-center w-full my-20 cursor-pointer">
      <div className="w-full sm:w-[1200px] flex flex-col items-center">
        <h3 className="text-3xl text-[#555555] border-b-[1px] pb-5 border-secondary">
          {title}
        </h3>
        <div className="flex flex-nowrap justify-center flex-col lg:flex-row lg:flex-wrap px-2 lg:justify-start gap-y-5 my-14 overflow-hidden items-center gap-1">
          {data.map((item, index) => (
            <div 
              key={index}
              onClick={() => handleClick(item.id)}
              className="w-full sm:w-[590px] h-[300px] overflow-hidden"
            >
              <img
                className="w-full h-full hover:scale-110 transition-transform duration-500 transform"
                src={getImgUrl(item.thumbnailImage.data.attributes)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
