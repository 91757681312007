import Spinner from "@/components/Spinner/Spinner";
import React, { createContext, useRef } from "react";

type LoadingData = {
    openLoading: Function,
    closeLoading: Function,
}

export const LoadingContext = createContext<LoadingData>({
    openLoading: () => { },
    closeLoading: () => { },
});


type LoadingProviderProps = {
    children: React.ReactNode;
}

export const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {

    const loadingElement = useRef<HTMLDivElement>(null);
    const openLoading = () => {
        if (loadingElement && loadingElement.current) {
            loadingElement.current.style.visibility = 'visible';
        }
    }

    const closeLoading = () => {
        if (loadingElement && loadingElement.current) {
            loadingElement.current.style.visibility = 'hidden';
        }
    }


    return (
        <LoadingContext.Provider value={{ openLoading, closeLoading }}>
            <div style={{ position: 'relative', overflow: 'hidden' }}>
                <div ref={loadingElement}
                className="absolute top-0 left-0 invisible flex items-center justify-center w-full h-full ">
                    <div className="z-[9999] aabsolute w-full h-full  bg-[#0000007e] opacity-70"></div>
                    <Spinner/>
                </div>
                {children}
            </div>

        </LoadingContext.Provider>
    );

};