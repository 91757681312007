import React, { createContext, useEffect } from 'react'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import i18n from '@/i18next';

const LanguageContext = createContext({
	language: 'en',
	setLanguage: (_: string) => { }
})

function LanguageProvider({ children }: { children: React.ReactNode }) {
	const {
		value: language,
		setValue: setLanguage
	} = useLocalStorage<string>('language', 'en')

	useEffect(() => {
		if (language) {
			i18n.changeLanguage(language);
		} else {
			i18n.changeLanguage('en');
		}
	}, [language]);

	return (
		<LanguageContext.Provider
			value={{
				language,
				setLanguage,
			}}
		>
			{children}
		</LanguageContext.Provider>
	)
}

export { LanguageProvider, LanguageContext }
