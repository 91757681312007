import React from "react";
import bg from "@/assets/images/bg_1.png";
export const ExampleBanner = (): JSX.Element => {
  return (
    <div className="w-full h-fit flex justify-center">
      <div className="w-[1200px] h-full flex flex-col items-center py-10 gap-20">
        <div className="h-[515px] w-full">
          <img src={bg} className="w-full h-full object-cover" />
        </div>
        <a
          href="/products/22"
          className="flex items-center justify-center bg-primary hover:bg-secondary px-4 text-white font-semibold text-3xl rounded-lg my-10"
        >
          <span>AD8033</span>
        </a>
        <div>
          <p className="text-xl text-center">
            New Calacatta Quartz & more colors brought by Andi, giving you a
            better life.
          </p>
        </div>
      </div>
    </div>
  );
};
