import React from "react";
import { ItemDownload } from "./ItemDownload";
import { useTranslation } from "react-i18next";
import { NEW_PRDODUCTS_CATALOG } from "@/contains";

export const Ecatalogs = (): JSX.Element => {
  const { t } = useTranslation();

  const downloadFile = async (path: string) => {
    try {
      const response = await fetch(path);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = path.split("/").pop() as string;

      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.log("Error downloading file");
    }
  };
  return (
    <div className="w-[92%] mx-[4%] flex justify-center mb-20">
      <div className="w-[1200px] h-full flex flex-col items-center py-12">
        <div className="text-[#555] flex gap-2 justify-center pb-5 border-b border-b-secondary mb-5">
          <h2 className="text-3xl">E-catalogs</h2>
        </div>
        <div className="flex flex-col w-full">
          <ItemDownload
            title={t("supportECatalog:downloadList.download1")}
            onClick={() => downloadFile("./assets/2024AndiNew.pdf")}
          />

          <ItemDownload
            title={t("supportECatalog:downloadList.download2")}
            onClick={() => (window.location.href = NEW_PRDODUCTS_CATALOG)}
          />
        </div>
      </div>
    </div>
  );
};
