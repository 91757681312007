import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
interface Dropdown {
    name: string;
    link: string;
    key?: string;
    main?: string;
}
interface Props {
    data: Dropdown[]
}
export const DropdownMenu = ({ data }: Props):JSX.Element => {
    const { t } = useTranslation()
    return (
        <div className="md:absolute bg-primary text-white top-full left-0 w-full md:w-[135px] z-[1000]">
            <ul>
                {data.map((nav, index) => (
                    <li key={index} className="hover:bg-secondary py-2 px-3">
                        <NavLink to={nav.link} className="block py-2 text-xs">{nav.key ? t(`navigation:${nav.main}.${nav.key}`) : nav.name }</NavLink>
                    </li>
                ))}
            </ul>
        </div>
    )
}