import React from "react";
import advantage_slide from "@/assets/images/advantage_slide.png";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Slide = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onClick = () => {
    navigate("/information");
  };
  return (
    <div className="w-full h-[1000px] flex justify-center">
      <div className="w-[1200px] h-full flex flex-col items-center justify-center gap-24">
        <div className="text-center text-3xl">
          <p>{t(`andi:advantages.slide.text1`)}</p>
          <div className="flex items-center gap-3">
            <button
              onClick={onClick}
              className="border-b-2 border-secondary py-2 px-2 flex gap-2 items-end hover:border-primary"
            >
              <h4 className="">{t(`andi:advantages.slide.text2`)}</h4>
              <HiOutlineArrowLongRight size={30} />
            </button>
          </div>
        </div>
        <div className="w-full h-[500px] bg-slate-200">
          <img className="w-full h-full object-cover" src={advantage_slide} />
        </div>
      </div>
    </div>
  );
};
