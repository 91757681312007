import React from "react";
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";

interface DefaultLayoutProps {
    children: React.ReactNode
}
export const DefaultLayout = ({ children }: DefaultLayoutProps): JSX.Element => {
    return (
        <div className="flex-col flex overflow-x-hidden">
            <Header />
            <div>
                {children}
            </div>
            <Footer />
        </div>
    )
}