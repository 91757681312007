import React from "react";
import { DefaultLayout } from "@/components/DefaultLayout";
import { Content } from "./Content";
import { HoverSlider } from "./HoverSlider";
import { Comparison } from "./Comparison";
import { Slide } from "./Slide";
import { useMobileTest } from "@/hooks";
import { Banner } from "@/components/Banner";
import advantage_bg from '@/assets/images/advantage_bg.png'
import { useTranslation } from "react-i18next";

export const Advantages = (): JSX.Element => {
    const { isMobile } = useMobileTest();
    const { t } = useTranslation();

    return (
        <DefaultLayout>
            <Banner title={t(`andi:advantages.title`)} bg={advantage_bg} />
            <Content />
            <HoverSlider isMobile={isMobile} />
            <Comparison />
            <Slide />
        </DefaultLayout>
    )
}