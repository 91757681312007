import { DefaultLayout } from "@/components/DefaultLayout";
import { useLoading } from "@/hooks/useLoading";
import { BlogApi } from "@/modules/blog/api";
import { IBlog } from "@/modules/blog/types";
import { getImgUrl, mappingDatasFromApi } from "@/modules/shared/helper";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

interface IBlogPost {
  id: number;
  title: string;
  summary: string;
  content: string;
  image: string;
}

export const News = (): JSX.Element => {
  const [news, setNews] = useState<IBlogPost[]>([]);
  const { openLoading, closeLoading } = useLoading();

  useEffect(() => {
    fetchAllNews();
  }, []);

  const fetchAllNews = async () => {
    try {
      openLoading();
      const response = await BlogApi.getBlogs();
      if (response?.data) {
        const mapData = mappingDatasFromApi(response?.data).map((item) => {
          return {
            id: item.id,
            title: item.Title,
            summary: item.Summary,
            content: item.Content,
            image: getImgUrl(item.FeaturedImage?.data.attributes),
          };
        });
        setNews(mapData);
      }
    } catch (error: any) {
      console.log("error", error);
    } finally {
      closeLoading();
    }
  };
  return (
    <DefaultLayout>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">News</h1>
        <div className="grid grid-cols-3 gap-4">
          {news.map((item) => (
            <NavLink key={item.title} to={`/news/${item.id}`} className="bg-white p-4 rounded shadow">
              <img src={item.image} alt={item.title} className="w-full h-40 object-cover mb-4 rounded" />
              <h2 className="text-xl font-bold mb-2">{item.title}</h2>
              <p className="text-gray-500">{item.summary}</p>
            </NavLink>
          ))}
        </div>
      </div>
    </DefaultLayout>
  );
};
