import { DefaultLayout } from "@/components/DefaultLayout";
import { useLoading } from "@/hooks/useLoading";
import { BlogApi } from "@/modules/blog/api";
import { IBlogsResponse } from "@/modules/blog/types";
import { getImgUrl, mappingDataFromApi } from "@/modules/shared/helper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const NewsDetail = (): JSX.Element => {
    const [news, setNews] = useState<IBlogsResponse>();
    const { openLoading, closeLoading } = useLoading();
    const { id } = useParams() || "";

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            openLoading();
            const response = await BlogApi.getBlogDetail(id?.toString() || "");
            if (response?.data) {
                const mapData = mappingDataFromApi(response?.data);
                setNews(mapData);
            }
        } catch (error: any) {
            console.log("error", error);
        } finally {
            closeLoading();
        }
    };
    return (
        <DefaultLayout>
            <center>
                <div className="container p-4 text-center">
                    {news && (
                        <article className="prose lg:prose-xl">
                            <h1 className="text-xl font-bold">{news.Title}</h1>
                            <p className="text-left italic text-sm my-4 pl-2" style={{ borderLeft: "4px solid #920000" }}>{news.Summary}</p>
                            <div className="flex justify-center my-4">
                                <img
                                    src={news.FeaturedImage ? getImgUrl(news.FeaturedImage.data.attributes) : ""}
                                    alt={news.Title}
                                    style={{ width: "40%", height: "auto" }}
                                />
                            </div>
                            <iframe
                                srcDoc={news.Content || ""}
                                sandbox="allow-scripts"
                                style={{ width: "100%", height: "500px", border: "none" }}
                            />
                        </article>
                    )}
                </div>
            </center>
        </DefaultLayout>
    );
};
