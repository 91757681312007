import React from "react";
import ReactPlayer from "react-player";

export const ProductPresentation = (): JSX.Element => {
    return (
        <div className="w-full flex justify-center mt-5">
            <div className="w-full lg:w-[1200px] h-full flex flex-col justify-around space-y-16 my-[100px] px-2 lg:px-0">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            url={require("@/assets/videos/notFound.mp4")}
                            light={require("@/assets/images/inspiration_gallery/AD6008.jpeg")}
                            controls
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            url={require("@/assets/videos/notFound.mp4")}
                            light={require("@/assets/images/inspiration_gallery/AD7026.jpeg")}
                            controls
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            url={require("@/assets/videos/notFound.mp4")}
                            light={require("@/assets/images/inspiration_gallery/AD7035.jpeg")}
                            controls
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            url={require("@/assets/videos/notFound.mp4")}
                            light={require("@/assets/images/inspiration_gallery/AD7037.jpeg")}
                            controls
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            url={require("@/assets/videos/notFound.mp4")}
                            light={require("@/assets/images/inspiration_gallery/AD7043.jpeg")}
                            controls
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            url={require("@/assets/videos/notFound.mp4")}
                            light={require("@/assets/images/inspiration_gallery/AD7045.jpeg")}
                            controls
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            url={require("@/assets/videos/notFound.mp4")}
                            light={require("@/assets/images/inspiration_gallery/AD7046.jpeg")}
                            controls
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            url={require("@/assets/videos/notFound.mp4")}
                            light={require("@/assets/images/inspiration_gallery/AD7047.jpeg")}
                            controls
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            url={require("@/assets/videos/notFound.mp4")}
                            light={require("@/assets/images/inspiration_gallery/AD7049.jpeg")}
                            controls
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            url={require("@/assets/videos/notFound.mp4")}
                            light={require("@/assets/images/inspiration_gallery/AD7058.jpeg")}
                            controls
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            url={require("@/assets/videos/notFound.mp4")}
                            light={require("@/assets/images/inspiration_gallery/AD7059.jpeg")}
                            controls
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            url={require("@/assets/videos/notFound.mp4")}
                            light={require("@/assets/images/inspiration_gallery/AD8005.jpeg")}
                            controls
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}