import React, { useContext, useEffect, useState } from "react";
import { faqs_en, faqs_vi } from "@/contains/faq";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "@/context/LanguageContext";

interface FAQItem {
    title: string;
    content: {
        question: string;
        answer: string[];
    }[];
}

export const Content = (): JSX.Element => {
    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const [faqs, setFaqs] = useState<FAQItem[]>([]);

    useEffect(() => {
        if (language === 'vi') {
            setFaqs(faqs_vi);
        } else {
            setFaqs(faqs_en);
        };
    }, [language])

    return (
        <div className="w-[92%] mx-[4%] flex justify-center mb-20">
            <div className="w-[1200px] h-full flex flex-col items-center py-12">
                <div className="text-[#555] flex gap-2 justify-center pb-4">
                    <h2 className="text-2xl font-bold">{t("supportFaq:title")}</h2>
                </div>
                <div className="bg-secondary h-[2px] w-24"></div>
                <div className="py-10 flex flex-col">
                    <div className="">
                        {faqs.map((faq, index) => (
                            <div key={index}>
                                <div className="mb-6 mt-20">
                                    <h4 className="text-xl font-bold">{faq.title}</h4>
                                </div>
                                {faq.content.map(content => (
                                    <div className="border-b border-dashed border-b-gray-dark pb-1 mt-10" key={content.question}>
                                        <h5 className="text-lg mb-3">{content.question}</h5>
                                        {content.answer.map((val, idx) => (  
                                            <p className="text-sm leading-loose" key={idx}>  
                                                {val} 
                                            </p>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}