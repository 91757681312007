import React from "react";
import { FieldError } from "react-hook-form";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError | undefined;
  children?: React.ReactNode;
  customclass?: string;
}

const Input = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    return (
      <>
        <input
          color={`${props.error?.message ? "red" : "gray"}`}
          {...props}
          ref={ref}
          className={`border outline-none px-[10px] py-[6px] text-sm ${
            props.error ? "border-red-500" : ""
          } ${props.customclass}`}
        >
          {props.children}
        </input>
        {props.error && (
          <p className="text-red-500 text-sm">{props.error.message}</p>
        )}
      </>
    );
  }
);

export default Input;
