import React from "react";
import { DefaultLayout } from "@/components/DefaultLayout";
import { Info } from "./Info";
import { Form } from "./Form";
import { Banner } from "@/components/Banner";
import { useTranslation } from "react-i18next";

const bg = 'https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30078701.jpg';
export const CutToSize = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <DefaultLayout>
            <Banner title={t("supportCutToSize:banner")} bg={bg} />
            <Info />
            <Form />
        </DefaultLayout>
    )
}