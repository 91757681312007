import banner1 from "@/assets/images/banner1.jpg";
import bg_3 from "@/assets/images/bg_1.png";
import design_kitchen_1 from "@/assets/images/design_kitchen_1.jpg";
import design_kitchen_2 from "@/assets/images/design_kitchen_2.jpg";
import design_kitchen_3 from "@/assets/images/design_kitchen_3.jpg";
import { EProjectRequests } from "@/modules/cuteToSize/enum";

export const BANNER_LIST = [
  {
    id: 1,
    imgSrc: banner1,
    link: "/products",
  },
  {
    id: 2,
    imgSrc:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30028980.jpg",
    link: "/series?category=new_series",
  },
  {
    id: 3,
    imgSrc: bg_3,
    link: "/inspiration_gallery",
  },
];

export const dropdownAndi = [
  { name: "Company", link: "/company", main: "andi", key: "company" },
  // {
  //   name: "Product Information",
  //   link: "/information",
  //   main: "andi",
  //   key: "productInformation",
  // },
  { name: "Advantages", link: "/advantages", main: "andi", key: "advantages" },
  // { name: "News", link: "/products", main: "andi", key: "news" },
  // { name: "Blog", link: "/products", main: "andi", key: "blog" },
];
export const dropdownProducts = [
  { name: "All colors", link: "/products", main: "products", key: "allColors" },
  {
    name: "New series",
    link: "/series?category=new_series",
    main: "products",
    key: "newSeries",
  },
  {
    name: "Natural series",
    link: "/series?category=natural_series",
    main: "products",
    key: "naturalSeries",
  },
  {
    name: "Fashion series",
    link: "/series?category=fashion_series",
    main: "products",
    key: "fashionSeries",
  },
  {
    name: "Classic series",
    link: "/series?category=classic_series",
    main: "products",
    key: "classicSeries",
  },
];
export const dropdownInspirations = [
  {
    name: "Product Video",
    link: "/video_inspiration",
    main: "inspiration",
    key: "productVideo",
  },
  {
    name: "Design inpriration",
    link: "/inspiration_gallery",
    main: "inspiration",
    key: "designInspiration",
  },
  {
    name: "Design your kitchen",
    link: "/design_your_kitchen",
    main: "inspiration",
    key: "designKitchen",
  },
];
export const dropdownSupport = [
  { name: "E-catalog", link: "/catalog", main: "support", key: "catalog" },
  {
    name: "Installation Guide",
    link: "/catalog",
    main: "support",
    key: "installationGuide",
  },
  {
    name: "Cut to size",
    link: "/cut_to_size",
    main: "support",
    key: "cutToSize",
  },
  {
    name: "Care & Maintenance",
    link: "/care_and_maintenance",
    main: "support",
    key: "careMaintenance",
  },
  {
    name: "Become a distributor",
    link: "/become_a_distributor",
    main: "support",
    key: "distributor",
  },
  { name: "FAQ", link: "/faq", main: "support", key: "faq" },
  { name: "Contact us", link: "/contact_us", main: "support", key: "contact" },
];
export const productCards = [
  {
    title: "Brand with a history of more than ten years",
    desc: "Professional quartz manufacturer.",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30028980.jpg",
    keyData: "card1",
  },
  {
    title: "Quartz countertops",
    desc: "Durable, Scratch-free, functional, design forward & Sophisticated.",
    image:
      "	https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30028981.jpg",
    keyData: "card2",
  },
  {
    title: "Healthy, Safe & Environmental Production",
    desc: "Up to 93% natural quartz sands and 7% resin contribute the most durable surfaces of engineered quartz.",
    image:
      "	https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30028979.jpg",
    keyData: "card3",
  },
];
export const sliders = [
  "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/31022788.jpg",
  "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/31022802.jpg",
  "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/31022793.jpg",
  "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/31022796.jpg",
  "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/31022799.jpg",
  "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/31022790.jpg",
];
export const news_series = [
  { link: "1", image: "https://i.imgur.com/Xv99WOO.jpeg" },
  { link: "2", image: "https://i.imgur.com/ySytPW1.jpeg" },
  { link: "3", image: "https://i.imgur.com/LoZn1rX.jpeg" },
  { link: "4", image: "https://i.imgur.com/tBuZmgd.jpeg" },
  { link: "5", image: "https://i.imgur.com/6uPEpz7.jpeg" },
  { link: "6", image: "https://i.imgur.com/fT3mE3B.jpeg" },
  { link: "7", image: "https://i.imgur.com/GVqdUaR.jpeg" },
  { link: "8", image: "https://i.imgur.com/w3ksBDC.jpeg" },
  { link: "9", image: "https://i.imgur.com/zrmmJs9.jpeg" },
  { link: "10", image: "https://i.imgur.com/40B9xmI.jpeg" },
  { link: "11", image: "https://i.imgur.com/6ZgMBT6.jpeg" },
  { link: "12", image: "https://i.imgur.com/oKxy6Hc.jpeg" },
];
export const natural_series = [
  {
    link: "1",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/41455733.jpg",
  },
  {
    link: "2",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057066.jpg",
  },
  {
    link: "3",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057067.jpg",
  },
  {
    link: "4",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057069.jpg",
  },
  {
    link: "5",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057070.jpg",
  },
  {
    link: "6",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057071.jpg",
  },
  {
    link: "7",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057072.jpg",
  },
  {
    link: "8",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057073.jpg",
  },
];
export const fashion_series = [
  {
    link: "1",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057079.jpg",
  },
  {
    link: "2",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057080.jpg",
  },
  {
    link: "3",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057081.jpg",
  },
  {
    link: "4",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057084.jpg",
  },
  {
    link: "5",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057083.jpg",
  },
  {
    link: "6",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057085.jpg",
  },
  {
    link: "7",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057086.jpg",
  },
  {
    link: "8",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057087.jpg",
  },
];
export const classic_series = [
  {
    link: "1",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057089.jpg",
  },
  {
    link: "2",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057091.jpg",
  },
  {
    link: "3",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057093.jpg",
  },
  {
    link: "4",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057094.jpg",
  },
  {
    link: "5",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057096.jpg",
  },
  {
    link: "6",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057098.jpg",
  },
  {
    link: "7",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057099.jpg",
  },
  {
    link: "8",
    image:
      "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30057101.jpg",
  },
];
export const product_infor_images = [
  "https://nwzimg.wezhan.cn/contents/sitefiles2063/10315872/images/31826860.jpg?x-oss-process=image/resize,m_lfit,h_200,w_200",
  "https://nwzimg.wezhan.cn/contents/sitefiles2063/10315872/images/31826861.jpg?x-oss-process=image/resize,m_lfit,h_200,w_200",
  "https://nwzimg.wezhan.cn/contents/sitefiles2063/10315872/images/31826907.jpg?x-oss-process=image/resize,m_lfit,h_200,w_200",
];

export const CATEGORY = {
  new_series: {
    id: 1,
    key: "newSeries",
  },
  natural_series: {
    id: 2,
    key: "naturalSeries",
  },
  fashion_series: {
    id: 3,
    key: "fashionSeries",
  },
  classic_series: {
    id: 4,
    key: "classicSeries",
  },
};

export const MOCK_DATA_CATEGORY = [
  {
    id: 1,
    attributes: {
      name: "New Series",
      createdAt: "2024-07-10T15:20:53.920Z",
      updatedAt: "2024-07-10T16:45:23.754Z",
      publishedAt: "2024-07-10T15:21:04.303Z",
      locale: "en",
    },
  },
  {
    id: 2,
    attributes: {
      name: "Natural Series",
      createdAt: "2024-07-10T16:45:44.580Z",
      updatedAt: "2024-07-10T16:47:17.565Z",
      publishedAt: "2024-07-10T16:47:17.437Z",
      locale: "en",
    },
  },
  {
    id: 3,
    attributes: {
      name: "Fashion Series",
      createdAt: "2024-07-10T16:46:14.999Z",
      updatedAt: "2024-07-10T16:47:26.230Z",
      publishedAt: "2024-07-10T16:47:26.102Z",
      locale: "en",
    },
  },
  {
    id: 4,
    attributes: {
      name: "Classic Series",
      createdAt: "2024-07-10T16:46:40.413Z",
      updatedAt: "2024-07-10T16:47:30.592Z",
      publishedAt: "2024-07-10T16:47:30.464Z",
      locale: "en",
    },
  },
];

export const NEW_PRDODUCTS_CATALOG =
  "https://drive.google.com/file/d/1PwMSh0on8B2cXUVFAJrXRTWDx4_7axWJ/view";

export const STYLE_KITCHEN_LIST = [
  {
    id: 1,
    imgSrc: design_kitchen_1,
  },
  {
    id: 2,
    imgSrc: design_kitchen_2,
  },
  {
    id: 3,
    imgSrc: design_kitchen_3,
  },
];

export const MOCK_DATA_DESIGN = [
  {
    id: 1,
    data: [
      {
        itemId: "default",
        name: "Default",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30139161.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30139161.jpg",
      },
      {
        itemId: "AD6001",
        name: "AD6001",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135651.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30139241.jpg",
      },
      {
        itemId: "AD6008",
        name: "AD6008",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135652.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30139242.jpg",
      },
      {
        itemId: "AD7026",
        name: "AD7026",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135653.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30139243.jpg",
      },
    ],
  },
  {
    id: 2,
    data: [
      {
        itemId: "default",
        name: "Default",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30139162.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30139162.jpg",
      },
      {
        itemId: "AD6001",
        name: "AD6001",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135651.jpg",
        previewSrc:
          "	https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30145690.jpg",
      },
      {
        itemId: "AD6008",
        name: "AD6008",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135652.jpg",
        previewSrc:
          "	https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30145692.jpg",
      },
      {
        itemId: "AD7026",
        name: "AD7026",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135651.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30145693.jpg",
      },
      {
        itemId: "AD7035",
        name: "AD7035",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135654.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30145694.jpg",
      },
    ],
  },
  {
    id: 3,
    data: [
      {
        itemId: "default",
        name: "Default",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30139163.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30139163.jpg",
      },
      {
        itemId: "AD6001",
        name: "AD6001",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135651.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30143401.jpg",
      },
      {
        itemId: "AD6008",
        name: "AD6008",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135652.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30143402.jpg",
      },
      {
        itemId: "AD7026",
        name: "AD7026",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135651.jpg",
        previewSrc:
          "	https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30143403.jpg",
      },
      {
        itemId: "AD7035",
        name: "AD7035",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135654.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30143404.jpg",
      },

      {
        itemId: "AD7037",
        name: "AD7037",
        imgSrc:
          "	https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135656.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30143411.jpg",
      },
      {
        itemId: "AD7043",
        name: "AD7043",
        imgSrc:
          "	https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135655.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30143416.jpg",
      },
      {
        itemId: "AD7045",
        name: "AD7045",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135651.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30143418.jpg",
      },
      {
        itemId: "AD7046",
        name: "AD7046",
        imgSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30135659.jpg",
        previewSrc:
          "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30143419.jpg",
      },
    ],
  },
];

export const PROJECT_REQUEST = {
  kitchen_tops: "Kitchen tops",
  island_tops: "Island tops",
  vanity_tops: "Vanity tops",
  dining_table: "Dining table",
};


export const PROJECT_REQUEST_LIST = [
  {
    id: EProjectRequests.kitchen_tops,
    name: "kitchenTops",
    label: "islandTops",
    image: "//nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30078703.jpg"
  },
  {
    id: EProjectRequests.island_tops,
    name: "chosenImage",
    label: "islandTops",
    image: "//nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30078704.jpg"
  },
  {
    id: EProjectRequests.vanity_tops,
    name: "chosenImage",
    label: "vanityTops",
    image: "//nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30078702.jpg"
  },
  {
    id: EProjectRequests.dining_table,
    name: "chosenImage",
    label: "diningTable",
    image: "//nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30078692.jpg"
  }
  
];
