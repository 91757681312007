import React from "react";

export const ProductPresentation = (): JSX.Element => {
    return (
        <div className="w-full flex justify-center mt-5">
            <div className="w-full lg:w-[1200px] h-full flex flex-col justify-around space-y-16 my-[100px] px-2 lg:px-0">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <img
                            src={require("@/assets/images/inspiration_gallery/AD6008.jpeg")}
                        />
                        <p className="inspiration-gallery-caption">AD6008</p>
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <img
                            src={require("@/assets/images/inspiration_gallery/AD7026.jpeg")}
                        />
                        <p className="inspiration-gallery-caption">AD7026</p>
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <img
                            src={require("@/assets/images/inspiration_gallery/AD7035.jpeg")}
                        />
                        <p className="inspiration-gallery-caption">AD7035</p>
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <img
                            src={require("@/assets/images/inspiration_gallery/AD7037.jpeg")}
                        />
                        <p className="inspiration-gallery-caption">AD7037</p>
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <img
                            src={require("@/assets/images/inspiration_gallery/AD7043.jpeg")}
                        />
                        <p className="inspiration-gallery-caption">AD7043</p>
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <img
                            src={require("@/assets/images/inspiration_gallery/AD7045.jpeg")}
                        />
                        <p className="inspiration-gallery-caption">AD7045</p>
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <img
                            src={require("@/assets/images/inspiration_gallery/AD7046.jpeg")}
                        />
                        <p className="inspiration-gallery-caption">AD7046</p>
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <img
                            src={require("@/assets/images/inspiration_gallery/AD7047.jpeg")}
                        />
                        <p className="inspiration-gallery-caption">AD7047</p>
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <img
                            src={require("@/assets/images/inspiration_gallery/AD7049.jpeg")}
                        />
                        <p className="inspiration-gallery-caption">AD7049</p>
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <img
                            src={require("@/assets/images/inspiration_gallery/AD7058.jpeg")}
                        />
                        <p className="inspiration-gallery-caption">AD7058</p>
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <img
                            src={require("@/assets/images/inspiration_gallery/AD7059.jpeg")}
                        />
                        <p className="inspiration-gallery-caption">AD7059</p>
                    </div>
                    <div className="col-span-3 sm:col-span-1 col3-player-style">
                        <img
                            src={require("@/assets/images/inspiration_gallery/AD8005.jpeg")}
                        />
                        <p className="inspiration-gallery-caption">AD8005</p>
                    </div>
                </div>
            </div>
        </div>
    )
}