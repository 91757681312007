import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import { Advantages } from '@/pages/Andi/Advantages';
import { AndiProductInformation } from '@/pages/Andi/AndiProductInformation/AndiProductInformation';
import { Company } from '@/pages/Andi/Company';
import { HomePage } from '@/pages/HomePage';
import { InspirationGallery } from '@/pages/Inspiration/InspirationGallery';
import { VideoInspiration } from '@/pages/Inspiration/VideoInspiration';
import { AllColors } from '@/pages/Products/AllColors';
import { ProductInfo } from '@/pages/Products/ProductInfo';
import { BecomeADistributor } from '@/pages/Support/BecomeADistributor';
import { CareMaintenance } from '@/pages/Support/CareMaintenance';
import { ContactUs } from '@/pages/Support/ContactUs';
import { CutToSize } from '@/pages/Support/CutToSize';
import { ECatalog } from '@/pages/Support/ECatalog';
import { FAQ } from '@/pages/Support/FAQ';

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { NewSeries } from './pages/Products/NewSeries';
import { DesignYourKitchen } from './pages/Inspiration/DesignYourKitchen/DesignYourKitchen';
import { DesignYourKitchenDetail } from './pages/Inspiration/DesignYourKitchenDetail/DesignYourKitchenDetail';
import { News } from './pages/News/News';
import { NewsDetail } from './pages/News/NewsDetail';
import { useCallback, useContext, useEffect, useState } from 'react';
import { HomeApi } from './modules/hompage/api';
import { AppContext } from './context/AppContext';

function App() {
  const [firstTime, setFirstTime] = useState(true);
  const { setAppConfig } = useContext(AppContext);

  const initData = useCallback(async () => {
    try {
      const response = await HomeApi.getHomePageConfig();
      console.log(response);
      if (response?.data?.attributes) {
        setAppConfig(response.data.attributes);
      }
    } catch (error: any) {
      console.log("error", error);
    }
  }, [firstTime]);

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      initData();
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/products' element={<AllColors />} />
        <Route path='/series' element={<NewSeries />} />
        <Route path='/series/:id' element={<ProductInfo />} />
        <Route path='/products/:id' element={<ProductInfo />} />
        <Route path='/company' element={<Company />} />
        <Route path='/advantages' element={<Advantages />} />
        <Route path='/cut_to_size' element={<CutToSize />} />
        <Route path='/care_and_maintenance' element={<CareMaintenance />} />
        <Route path='/information' element={<AndiProductInformation />} />
        <Route path='/video_inspiration' element={<VideoInspiration />} />
        <Route path='/inspiration_gallery' element={<InspirationGallery />} />
        <Route path='/design_your_kitchen' element={<DesignYourKitchen />} />
        <Route path='/design_your_kitchen/:id' element={<DesignYourKitchenDetail />} />
        <Route path='/catalog' element={<ECatalog />} />
        <Route path='/become_a_distributor' element={<BecomeADistributor />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='/contact_us' element={<ContactUs />} />
        <Route path='/news' element={<News />} />
        <Route path='/news/:id' element={<NewsDetail />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
