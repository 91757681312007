export enum EDescription {
  HEADING = "heading",
  PARAGRAPH = "paragraph",
  IMAGE = "image",
  CODE = "code",
  QUOTE = "quote",
  LIST = "list",
}

export enum EChildrenDescription {
  TEXT = "text",
  LINK = "link",
  LIST_ITEM = "list-item",
  CODE = "code",
}
