import React from "react";
import { Row } from "./Row";
import { useTranslation } from "react-i18next";

export const Content = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="w-[92%] mx-[4%] flex justify-center">
            <div className="w-[1200px] py-14">
                <div className="flex flex-col items-center justify-center">
                    <h1 className="text-xl font-bold text-[#555]">{t("supportCare:title")}</h1>
                    <div className="h-[1px] w-[90px] bg-secondary mt-4"></div>
                </div>
                <p className="my-10 text-sm leading-loose">
                    {t("supportCare:content")}
                </p>
                <div className="my-28 text-black">
                    <h4 className="font-semibold text-lg mb-6"> {t("supportCare:dailyCleaning.title")}</h4>
                    <div className="text-sm">
                        <Row index={t("supportCare:dailyCleaning.step1.step")}
                            content={t("supportCare:dailyCleaning.step1.instruction")}
                        />
                        <Row index={t("supportCare:dailyCleaning.step2.step")}
                            content={t("supportCare:dailyCleaning.step2.instruction")}
                        />
                        <Row index={t("supportCare:dailyCleaning.step3.step")}
                            content={t("supportCare:dailyCleaning.step3.instruction")}
                        />
                        <Row index={t("supportCare:dailyCleaning.step4.step")}
                            content={t("supportCare:dailyCleaning.step4.instruction")}
                        />
                    </div>
                </div>
                <div className="my-28 text-black">
                    <h4 className="font-semibold text-lg mb-6"> {t("supportCare:stubbornStain.title")}</h4>
                    <div className="text-sm">
                        <Row index={t("supportCare:stubbornStain.step1.step")}
                            content={t("supportCare:stubbornStain.step1.instruction")}
                        />
                        <Row index={t("supportCare:stubbornStain.step2.step")}
                            content={t("supportCare:stubbornStain.step2.instruction")}
                        />
                        <Row index={t("supportCare:stubbornStain.step3.step")}
                            content={t("supportCare:stubbornStain.step3.instruction")}
                        />
                        <Row index={t("supportCare:stubbornStain.step4.step")}
                            content={t("supportCare:stubbornStain.step4.instruction")}
                        />
                    </div>
                </div>
                <div className="my-28 text-black">
                    <h4 className="font-semibold text-lg mb-6"> {t("supportCare:dryStain.title")}</h4>
                    <div className="text-sm">
                        <Row index={t("supportCare:dryStain.step1.step")}
                            content={t("supportCare:dryStain.step1.instruction")}
                        />
                        <Row index={t("supportCare:dryStain.step2.step")}
                            content={t("supportCare:dryStain.step2.instruction")}
                        />
                    </div>
                </div>
                <div className="my-28 text-black">
                    <h4 className="font-semibold text-lg mb-6"> {t("supportCare:rustAndMetalMarks.title")}</h4>
                    <div className="text-sm">
                        <Row index={t("supportCare:rustAndMetalMarks.step1.step")}
                            content={t("supportCare:rustAndMetalMarks.step1.instruction")}
                        />
                        <Row index={t("supportCare:rustAndMetalMarks.step2.step")}
                            content={t("supportCare:rustAndMetalMarks.step2.instruction")}
                        />
                        <Row index={t("supportCare:rustAndMetalMarks.step3.step")}
                            content={t("supportCare:rustAndMetalMarks.step3.instruction")}
                        />
                        <Row index={t("supportCare:rustAndMetalMarks.step4.step")}
                            content={t("supportCare:rustAndMetalMarks.step4.instruction")}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}