import React, { createContext, useState } from 'react';

const AppContext = createContext({
    appConfig: {} as any,
    setAppConfig: (_: any) => { }
})

function AppConfigProvider({ children }: { children: React.ReactNode }) {
    const [appConfig, setAppConfig] = useState<any>({});

    return (
        <AppContext.Provider
            value={{
                appConfig,
                setAppConfig,
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export { AppConfigProvider, AppContext };

