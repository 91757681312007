import React, { useContext, useEffect, useState } from "react";
import { DefaultLayout } from "@/components/DefaultLayout";
import { Banner } from "./Banner";
import { BannerLists } from "./BannerLists";
import { FeatureSection } from "./FeatureSection";
import { Sliders } from "./Sliders";
import { ProductPresentation } from "./ProductPresentation";
import { ExampleBanner } from "./ExampleBanner";
import { ProductsApi } from "@/modules/productsPage/api";
import { getImgUrl, mappingDatasFromApi } from "@/modules/shared/helper";
import { useLoading } from "@/hooks/useLoading";
import { ISliders } from "@/modules/hompage/types";
import { AppContext } from "@/context/AppContext";

export const HomePage = (): JSX.Element => {
  const [products, setProducts] = useState<ISliders[]>([]);
  const { openLoading, closeLoading } = useLoading();
  const { appConfig } = useContext(AppContext);

  useEffect(() => {
    fetchAllProducts();
  }, []);

  const fetchAllProducts = async () => {
    try {
      openLoading();
      const response = await ProductsApi.getProducts();
      if (response?.data) {
        const mapData = mappingDatasFromApi(response?.data).map((item) => {
          return {
            imgSrc: getImgUrl(item.thumbnailImage.data.attributes),
            id: item.id,
          };
        });
        setProducts(mapData);
      }
    } catch (error: any) {
      console.log("error", error);
    } finally {
      closeLoading();
    }
  };
  return (
    <DefaultLayout>
      <Banner />
      <BannerLists />
      <FeatureSection />
      <div dangerouslySetInnerHTML={{ __html: appConfig?.product_silder_header }} />
      <Sliders sliders={products} />
      <ProductPresentation />
      <ExampleBanner />
    </DefaultLayout>
  );
};
