import React, { useEffect, useState } from "react";
import { DefaultLayout } from "@/components/DefaultLayout";
import { ProductList } from "./ProductList";
import { Banner } from "@/components/Banner";
import bg from "@/assets/images/bg_2.png";
import { useTranslation } from "react-i18next";
import { ProductsApi } from "@/modules/productsPage/api";
import { IProductsResponse } from "@/modules/productsPage/types";
import { mappingDatasFromApi } from "@/modules/shared/helper";
import { useLoading } from "@/hooks/useLoading";

export const AllColors = (): JSX.Element => {
  const { t } = useTranslation();
  const [products, setProducts] = useState<IProductsResponse[]>([]);
  const { openLoading, closeLoading } = useLoading();

  useEffect(() => {
    fetchAllProducts();
  }, []);

  const fetchAllProducts = async () => {
    try {
      openLoading();
      const response = await ProductsApi.getProducts();
      if (response?.data) {
        const mapData = mappingDatasFromApi(response?.data);
        setProducts(mapData);
      }
    } catch (error: any) {
      console.log("error", error);
    } finally {
      closeLoading();
    }
  };
  return (
    <DefaultLayout>
      <Banner title={t(`productList:title`)} bg={bg} />
      <ProductList data={products} />
    </DefaultLayout>
  );
};
