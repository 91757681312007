import React, { ChangeEvent } from "react";
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  label: string;
  image: string;
  selected: string;
  checked?: boolean;
}
export const Checkbox = ({
  id,
  name,
  label,
  image,
  selected,
  checked,
  ...props
}: Props): JSX.Element => {
  return (
    <div className="w-full">
      <label className="w-full flex flex-col items-center" htmlFor={id}>
        <div
          className={`w-full border ${
            selected === id ? "border-blue-500" : "border-transparent"
          } flex justify-center`}
        >
          <div className="w-[120px] h-[120px]">
            <img className="w-full h-full" src={image} />
          </div>
        </div>
        <div>
          <input
            {...props}
            type="checkbox"
            id={id}
            name={name}
            checked={checked}
          />
          <span>{label}</span>
        </div>
      </label>
    </div>
  );
};
