import React from "react";
import spinnerImg from "@/assets/images/spinner.svg"

const Spinner: React.FC = () => {
  return (
    <div className=" z-[10000] fixed top-[50%] translate-y-[-50%] bg-white w-[109px] h-[109px] rounded-lg p-4 flex flex-col justify-center items-center gap-[10px]">
      <img className="animate-spin" src={spinnerImg} alt="" />
    </div>
  );
};

export default Spinner;