import React from "react";
import { useTranslation } from "react-i18next";

export const Info = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="w-[92%] mx-[4%] h-[700px] flex justify-center">
            <div className="w-[1200px] h-full flex flex-col justify-evenly">
                <div className="flex flex-col md:flex-row gap-4 h-[500px]">
                    <div className="w-full md:w-[600px] h-full">
                        <img className="w-full h-full object-cover" src="https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30078700.jpg"/>
                    </div>
                    <div className="flex flex-1 flex-col justify-center">
                        <div className="flex flex-col justify-center gap-3 mb-7">
                            <div className="text-black font-semibold text-xl py-2 flex gap-2 items-center">
                                <h4 className="text-lg">{t("supportCutToSize:techDetail.title")}</h4>
                            </div>
                            <div className="h-[2px] w-20 bg-secondary"></div>
                        </div>
                        <div className="">
                            <p className="leading-relaxed font-normal text-black text-sm">
                                {t("supportCutToSize:techDetail.content")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}