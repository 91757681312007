import axios from "@/apis/axios";
import { IResponseResult } from "../shared/types";
import { IBlogsResponseResults } from "./types";

export class BlogApi {
  static getBlogs(): Promise<IResponseResult<IBlogsResponseResults[]>> {
    return axios.get(`blogs?fields[0]=Title&fields[1]=Summary&populate=*`).then((res) => res.data);
  }

  static getBlogDetail(
    id: string
  ): Promise<IResponseResult<IBlogsResponseResults>> {
    return axios.get(`blogs/${id}?populate=*`).then((res) => res.data);
  }
}
