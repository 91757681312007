import bg from "@/assets/images/bg_2.png";
import { IoIosArrowDown } from "react-icons/io";

interface BannerProps {
  title: string;
  bg: string;
  className?: string;
}
export const Banner = ({
  title,
  bg,
  className = "lg:h-[550px]",
}: BannerProps): JSX.Element => {
  return (
    <div className={`w-full h-[200px] relative flex items-end ${className}`}>
      <div className="absolute top-0 bottom-0 right-0 left-0 w-full h-full">
        <img className="w-full h-full object-cover" src={bg} />
      </div>
      <div className="z-10 lg:bg-[transparent] bg-[#55555555]  h-[100%] lg:h-[auto] w-full pt-2 lg:mb-10 flex flex-col justify-end items-center">
        <h2 className="text-white text-4xl text-center uppercase">{title}</h2>
        <div className="mt-3 text-white flex justify-center">
          <IoIosArrowDown size={40} />
        </div>
      </div>
      <div className="absolute bottom-0 top-3/4 inset-0 bg-gradient-to-b from-transparent to-zinc-900 pointer-events-none"></div>
    </div>
  );
};
