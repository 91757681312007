import advantages1 from "@/assets/images/advantage_1.png"
import advantages2 from "@/assets/images/advantage_2.png"
import advantages3 from "@/assets/images/advantage_3.png"
import advantages4 from "@/assets/images/advantage_4.png"
import advantages5 from "@/assets/images/advantage_5.png"

export const advantages = [
    {
        name: "Durable",
        header_image: "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30336485.jpg",
        content: "Andy quartz surfaces offers not only good appearance but also strong anti-aging and antioxidant capacity. With harder and stronger surfaces,  Andy quartz counter-tops are perfect for taking on the wear and tear of daily life flawlessly.",
        image: advantages1,
        key: "tab1"
    },
    {
        name: "Easy to Clean",
        header_image: "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30336487.jpg",
        content: "For every day cleaning use a soft sponge or dishcloth with warm water, no need for sealing, and essentially effortless to maintain.",
        image: advantages2,
        key: "tab2"
    },
    {
        name: "Stainless",
        header_image: "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30336489.jpg",
        content: "Andy Quartz surfaces are stain free from wine, coffee, oil and soy-sauce,etc.",
        image: advantages3,
        key: "tab3"

    },
    {
        name: "Scratch-resistant",
        header_image: "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30336491.jpg",
        content: "With high Moh's hardness, Andy quartz counter-tops are  anti-scratch and wear-resistant.",
        image: advantages4,
        key: "tab4"

    },
    {
        name: "Heat Resistant",
        header_image: "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30336494.jpg",
        content: "Andy quartz is heat resistant, but using a trivet is required for very high temperatures.",
        image: advantages5,
        key: "tab5"

    },
    {
        name: "Anti Bacterial",
        header_image: "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30336497.jpg",
        content: "Andy Quartz surface and internal structure are dense and uniform, without pores and gaps. The content of high-quality antibiotics helps to prevent bacterial breeding, which is good to food and health.",
        image: advantages1,
        key: "tab6"

    },
    {
        name: "Eco-friendly",
        header_image: "https://nwzimg.wezhan.cn/contents/sitefiles2055/10275706/images/30336499.jpg",
        content: "Due to its durability and low maintenance, supporting a healthier environment and better use of material resources.",
        image: advantages2,
        key: "tab7"

    }
]