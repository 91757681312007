import React from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineArrowLongRight } from "react-icons/hi2";

export const ProductPresentation = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="w-[92%] flex justify-center mt-5 mx-[4%]">
            <div className="w-full md:w-[1200px] h-full flex flex-col justify-around space-y-16 my-[100px]">
                <div className="flex flex-col md:flex-row gap-12">
                    <div className="w-full md:w-[600px] h-full">
                        <img className="w-full h-full object-cover" src={require("@/assets/images/product_information/1.jpeg")} />
                    </div>
                    <div className="flex flex-1 flex-col justify-center">
                        <h4 className="text-xl font-bold">{t(`andi:productInfor.leading1`)}</h4><br />
                        <div className="border-b-2 border-secondary mb-4" style={{ width: '50px' }} />
                        <div className="">
                            <p className="leading-[32px]">
                                {t(`andi:productInfor.content1`)}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse md:flex-row gap-12">
                    <div className="flex flex-1 flex-col justify-center">
                        <h4 className="text-xl font-bold">{t(`andi:productInfor.leading2`)}</h4><br />
                        <div className="border-b-2 border-secondary mb-4" style={{ width: '50px' }} />
                        <div className="">
                            <p className="leading-[32px]">
                            {t(`andi:productInfor.content2`)}
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-[600px] h-full">
                        <img className="w-full h-full object-cover" src={require("@/assets/images/product_information/2.jpeg")} />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-12">
                    <div className="w-full md:w-[600px] h-full">
                        <img className="w-full h-full object-cover" src={require("@/assets/images/product_information/3.jpeg")} />
                    </div>
                    <div className="flex flex-1 flex-col justify-center">
                        <h4 className="text-xl font-bold">{t(`andi:productInfor.leading3`)}</h4><br />
                        <div className="border-b-2 border-secondary mb-4" style={{ width: '50px' }} />
                        <div className="">
                            <p className="leading-[32px]">
                            {t(`andi:productInfor.content3`)}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse md:flex-row gap-12">
                    <div className="flex flex-1 flex-col justify-center">
                        <h4 className="text-xl font-bold">{t(`andi:productInfor.leading4`)}</h4><br />
                        <div className="border-b-2 border-secondary mb-4" style={{ width: '50px' }} />
                        <div className="">
                            <p className="leading-[32px]">
                            {t(`andi:productInfor.content4`)}
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-[600px] h-full">
                        <img className="w-full h-full object-cover" src={require("@/assets/images/product_information/4.jpeg")} />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-12">
                    <div className="w-full md:w-[600px] h-full">
                        <img className="w-full h-full object-cover" src={require("@/assets/images/product_information/5.jpeg")} />
                    </div>
                    <div className="flex flex-1 flex-col justify-center">
                        <h4 className="text-xl font-bold">{t(`andi:productInfor.leading5`)}</h4><br />
                        <div className="border-b-2 border-secondary mb-4" style={{ width: '50px' }} />
                        <div className="">
                            <p className="leading-[32px]">
                            {t(`andi:productInfor.content5`)}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse md:flex-row gap-12">
                    <div className="flex flex-1 flex-col justify-center">
                        <h4 className="text-xl font-bold">{t(`andi:productInfor.leading6`)}</h4><br />
                        <div className="border-b-2 border-secondary mb-4" style={{ width: '50px' }} />
                        <div className="">
                            <p className="leading-[32px]">
                            {t(`andi:productInfor.content6`)}
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-[600px] h-full">
                        <img className="w-full h-full object-cover" src={require("@/assets/images/product_information/6.jpeg")} />
                    </div>
                </div>
            </div>
        </div>
    )
}