import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { IContactForm, ISendMailBody } from "@/modules/cuteToSize/type";
import { CutToSizeApi } from "@/modules/cuteToSize/api";
import { PROJECT_REQUEST, PROJECT_REQUEST_LIST } from "@/contains";
import { useLoading } from "@/hooks/useLoading";
import Input from "@/components/Input/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox } from "./Checkbox";
import { emailReceive } from "@/modules/shared/helper";

const schema = yup.object({
  name: yup.string().required("Required field"),
  phoneNumber: yup.string().required("Required field"),
  countryCode: yup.string().required("Required field"),
  email: yup
    .string()
    .email("Please fill in the correct email address")
    .required("Required field"),
  region: yup.string().required("Required field"),
  company: yup.string(),
  request: yup.string(),
  projectRequests: yup
    .array()
    .required("Required field")
    .min(1, "Required field"),
  colour: yup.string().required("Required field"),
  area: yup.string().required("Required field"),
});

const state = {
  [PROJECT_REQUEST.kitchen_tops]: false,
  [PROJECT_REQUEST.island_tops]: false,
  [PROJECT_REQUEST.vanity_tops]: false,
  [PROJECT_REQUEST.dining_table]: false,
};

export const Form = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IContactForm>({
    resolver: yupResolver(schema),
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedChecked, setSelectedChecked] = useState(state);
  const handleChangeCheckbox = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => {
    const updatedState = {
      ...selectedChecked,
      [e.target.id]: !selectedChecked[e.target.id],
    };
    setSelectedChecked(updatedState);

    const selectedItems = updatedState[e.target.id]
      ? [...selectedValues, e.target.id]
      : selectedValues.filter((item) => item !== e.target.id);

    setSelectedValues(selectedItems);
    onChange(selectedItems);
  };
  const { openLoading, closeLoading } = useLoading();
  const onSubmit: SubmitHandler<IContactForm> = async (data) => {
    try {
      const {
        name,
        phoneNumber,
        countryCode,
        email,
        region,
        company,
        request,
        projectRequests,
        colour,
        area,
      } = data;
      openLoading();
      const body: ISendMailBody = {
        to: emailReceive || "",
        subject: "Cut To Size",
        text: `Name: ${name}<br>Phone Number: ${countryCode} ${phoneNumber}<br>Email: ${email}<br>Region: ${region}<br>Company: ${company}<br>Request: ${request}<br>Project request: ${
          projectRequests
            .map(
              (item) => PROJECT_REQUEST[item as keyof typeof PROJECT_REQUEST]
            )
            .join(", ") || ""
        }<br>Colour : ${colour}<br>Area: ${area}`,
      };
      const res = await CutToSizeApi.sendMail(body);
      if (res) {
        reset(undefined);
        setSelectedValues([]);
        setSelectedChecked(state);
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoading();
    }
  };

  return (
    <div className="w-[92%] mx-[4%] flex justify-center mb-40">
      <div className="w-full sm:w-[600px] md:w-[800px] lg:w-[1000px] xl:w-[1200px] h-full py-10 text-[#333]">
        <div className="flex flex-col items-center mb-6">
          <h1 className="font-semibold text-2xl">
            {t("supportCutToSize:form.title")}
          </h1>
          <p className="w-[80px] h-[1px] bg-[#333] my-4"></p>
          <p className="text-base ">{t("supportCutToSize:form.content")}</p>
          <p className="text-xl mt-4">
            {t("supportCutToSize:form.contactInformation")}
          </p>
        </div>
        <form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col sm:flex-row my-2">
            <div className="flex flex-1 flex-col py-2 px-[30px]">
              <label className="text-sm mb-[6px]">
                {t("supportCutToSize:form.label.name")}{" "}
                <span className="text-secondary">*</span>
              </label>
              <Input {...register("name")} error={errors.name} />
            </div>

            <div className="flex flex-1 py-2 px-[30px] gap-[60px] items-end">
              <div className="flex flex-col">
                <label className="text-sm mb-[6px]">
                  {t("supportCutToSize:form.label.telephone")}{" "}
                  <span className="text-secondary">*</span>
                </label>
                <Input
                  type="text"
                  placeholder={t(
                    "supportCutToSize:form.placeholder.countryCode"
                  )}
                  {...register("countryCode")}
                  error={errors.countryCode}
                  className="w-full border outline-none px-[10px]  py-[6px] text-sm"
                />
              </div>
              <div className="w-full">
                <Input
                  type="text"
                  placeholder={t(
                    "supportCutToSize:form.placeholder.phoneNumber"
                  )}
                  {...register("phoneNumber")}
                  error={errors.phoneNumber}
                  className="w-full border outline-none px-[10px]  py-[6px] text-sm"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row my-2">
            <div className="flex flex-1 flex-col py-2 px-[30px]">
              <label className="text-sm mb-[6px]">
                {t("supportCutToSize:form.label.email")}{" "}
                <span className="text-secondary">*</span>
              </label>
              <Input
                type="text"
                {...register("email")}
                error={errors.email}
                className="border outline-none px-[10px] py-[6px] text-sm"
              />
            </div>
            <div className="flex flex-1 flex-col py-2 px-[30px]">
              <label className="text-sm mb-[6px]">
                {t("supportCutToSize:form.label.region")}{" "}
                <span className="text-secondary">*</span>
              </label>
              <Input
                type="text"
                {...register("region")}
                error={errors.region}
                className="border outline-none px-[10px] py-[6px] text-sm"
              />
            </div>
          </div>

          <div className="flex flex-col sm:flex-row my-2">
            <div className="flex flex-1 flex-col py-2 px-[30px]">
              <label className="text-sm mb-[6px]">
                {t("supportCutToSize:form.label.company")}
              </label>
              <Input
                type="text"
                {...register("company")}
                className="border outline-none px-[10px] py-[6px] text-sm"
              />
            </div>
            <div className="flex flex-1 flex-col py-2 px-[30px]">
              <label className="text-sm mb-[6px]">
                {t("supportCutToSize:form.label.request")}
              </label>
              <Input
                type="text"
                {...register("request")}
                className="border outline-none px-[10px] py-[6px] text-sm"
              />
            </div>
          </div>

          <p className="my-4 text-center text-xl">
            {t("supportCutToSize:form.projectRequest")}
          </p>
          <div className="px-[30px]">
            <p className="mb-1">
              {t("supportCutToSize:form.projectRequestLabel")}{" "}
              <span className="text-secondary">*</span>
            </p>
            <div className="flex flex-wrap md:flex-nowrap gap-[30px]">
              <Controller
                name="projectRequests"
                control={control}
                render={({ field: { onChange } }) => (
                  <>
                    {PROJECT_REQUEST_LIST.map((item) => (
                      <Checkbox
                        key={item.id}
                        id={item.id}
                        name="chosenImage"
                        label={t(`supportCutToSize:form.radio.${item.label}`)}
                        selected={
                          selectedValues.find(
                            (selected) => selected === item.id
                          ) || ""
                        }
                        checked={selectedChecked[item.id as keyof typeof state]}
                        onChange={(e) => handleChangeCheckbox(e, onChange)}
                        image={item.image}
                      />
                    ))}
                  </>
                )}
              />
            </div>
            {errors.projectRequests && (
              <p className="text-red-500 text-sm">Required field</p>
            )}
          </div>
          <p className="my-4 text-center text-xl">
            {t("supportCutToSize:form.colorNameSize")}
          </p>
          <div className="flex flex-col sm:flex-row my-2">
            <div className="flex flex-1 flex-col py-2 px-[30px]">
              <label className="text-sm mb-[6px]">
                {t("supportCutToSize:form.label.colour")}{" "}
                <span className="text-secondary">*</span>
              </label>
              <Input
                type="text"
                {...register("colour")}
                error={errors.colour}
                className="border outline-none px-[10px] py-[6px] text-sm"
              />
            </div>
            <div className="flex flex-1 flex-col py-2 px-[30px]">
              <label className="text-sm mb-[6px]">
                {t("supportCutToSize:form.label.area")}{" "}
                <span className="text-secondary">*</span>
              </label>
              <Input
                type="text"
                {...register("area")}
                error={errors.area}
                className="border outline-none px-[10px] py-[6px] text-sm"
              />
            </div>
          </div>
          <div className="flex justify-center my-2">
            <button className="bg-secondary rounded-sm text-sm text-white py-2 w-[226px]">
              {t("supportCutToSize:form.btn")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
