import React from "react";
import { DefaultLayout } from "@/components/DefaultLayout";
import { Ecatalogs } from "./ECatalogs";
import { Banner } from "@/components/Banner";

const bg = 'https://nwzimg.wezhan.cn/contents/sitefiles2063/10315872/images/47720977.jpg';  
export const ECatalog = (): JSX.Element => {
  return (
    <DefaultLayout>
      <Banner title="E-Catalogs" bg={bg} />
      <Ecatalogs />
    </DefaultLayout>
  );
};
