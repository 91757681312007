import { DefaultLayout } from "@/components/DefaultLayout";
import { Banner } from "@/components/Banner";
import bg from "@/assets/images/bg_2.png";
import { useTranslation } from "react-i18next";
import { ProductItem } from "../AllColors/ProductList/ProductItem";
import { ProductsApi } from "@/modules/productsPage/api";
import { IProductsResponse } from "@/modules/productsPage/types";
import { useEffect, useState } from "react";
import { mappingDatasFromApi } from "@/modules/shared/helper";
import { useLocation } from "react-router-dom";
import { CATEGORY } from "@/contains";
import { useLoading } from "@/hooks/useLoading";

interface CategoryType {
  [key: string]: {
    id: number;
    key: string;
  };
}

export const NewSeries = (): JSX.Element => {
  const { t } = useTranslation();
  const [products, setProducts] = useState<IProductsResponse[]>([]);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");
  const categoryId = (CATEGORY as CategoryType)?.[category || ""].id;
  const categoryKey = (CATEGORY as CategoryType)?.[category || ""].key;

  useEffect(() => {
    fetchAllProducts();
  }, [category]);
  const { openLoading, closeLoading } = useLoading();

  const fetchAllProducts = async () => {
    try {
      openLoading();
      const response = await ProductsApi.getProducts(categoryId);
      if (response?.data) {
        const mapData = mappingDatasFromApi(response?.data);
        setProducts(mapData);
      }
    } catch (error: any) {
      console.log("error", error);
    } finally {
      closeLoading();
    }
  };

  return (
    <DefaultLayout>
      <Banner title={t(`productList:${categoryKey}`)} bg={bg} />
      <div className="w-full">
        <ProductItem
          title={t(`navigation:products.${categoryKey}`)}
          data={products}
        />
      </div>
    </DefaultLayout>
  );
};
