import React from "react";

interface Props {
    index: string;
    content: string
}
export const Row = ({ index, content }: Props): JSX.Element => {
    return (
        <p className="flex gap-3 mb-[6px]">
            <span className="text-secondary">{index}</span>
            <span>{content}</span>
        </p>
    )
}